import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {GgrComponent} from './ggr/ggr.component';
import {IframeComponent} from '../shared/iframe/iframe.component';
import {PaymentsComponent} from './payments/payments.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'financial/ggr',
        pathMatch: 'full'
    },
    {
        path: 'reconciliation',
        children: [
            {
                path: 'profitloss',
                component: IframeComponent
            },
            {
                path: 'bookings',
                component: IframeComponent
            },
            {
                path: 'cronjobs',
                component: IframeComponent
            },
            {
                path: 'downloads',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'financial',
        children: [
            {
                path: 'ggr',
                component: GgrComponent
            },
            {
                path: 'ngr',
                component: GgrComponent
            },
            {
                path: 'deposits',
                component: GgrComponent
            },
            {
                path: 'payments',
                component: PaymentsComponent
            },
            {
                path: 'activity',
                component: GgrComponent
            },
            {
                path: 'churn',
                component: GgrComponent
            },
            {
                path: 'active',
                component: GgrComponent
            }
        ]
    }


];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class ReportingRoutingModule {
}
