import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IframeComponent} from '../shared/iframe/iframe.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'account/search',
        pathMatch: 'full'
    },
    {
        path: 'payments',
        children: [
            {
                path: 'deposits',
                component: IframeComponent
            },
            {
                path: 'cashouts',
                component: IframeComponent
            },            {
                path: 'limits',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'admin',
        children: [
            {
                path: 'overview',
                component: IframeComponent
            },
            {
                path: 'adjustments',
                component: IframeComponent
            },            {
                path: 'accountdetails',
                component: IframeComponent
            },
            {
                path: 'accountstatus',
                component: IframeComponent
            },
            {
                path: 'transdetails',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'fx',
        children: [
            {
                path: 'rates',
                component: IframeComponent
            },
            {
                path: 'accounting',
                component: IframeComponent
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class TransactionsRoutingModule {
}
