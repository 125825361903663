<div class="overlay_menu">
	<button class="btn btn-primary btn-icon btn-icon-mini btn-round"><i class="zmdi zmdi-close"></i></button>
	<div class="container">
		<div class="row clearfix">
			<div class="card links">
				<div class="body">
					<div class="row">
						<div class="col-lg-3 col-md-6 col-sm-12">
							<h6>Reporting</h6>
							<ul class="list-unstyled">
								<li><a routerLink="/reporting/ggr">GGR</a></li>
							</ul>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
							<h6>Operations</h6>
							<ul class="list-unstyled">
								<li><a routerLink="/operations/tickets">Tickets</a></li>
							</ul>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
							<h6>Player</h6>
							<ul class="list-unstyled">
								<li><a routerLink="/player/search">Search</a></li>
							</ul>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-12">
							<h6>About</h6>
							<ul class="list-unstyled">
								<li><a href="" target="_blank">Nothing yet</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row clearfix">
			<div class="col-lg-12 col-md-12">
				<div class="social">
					<a class="icon" href="https://www.facebook.com/BonkersBet/" target="_blank"><i class="zmdi zmdi-facebook"></i></a>
					<a class="icon" href="https://twitter.com/BonkersBet"><i class="zmdi zmdi-twitter"></i></a>
					<p>Coded in the UpsideZone
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Overlay For Sidebars -->
<div class="overlay"></div>
<!-- Top Bar -->
<nav class="navbar">
	<ul class="nav navbar-nav navbar-left">
		<li>
			<div class="navbar-header">
				<a href="javascript:void(0);" class="bars"></a>
				<a class="navbar-brand" routerLink="/dashboard"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" width="30" alt="sQuare"><span class="m-l-10">Bonkers</span></a>
			</div>
		</li>
		<li>
			<a href="javascript:void(0);" class="menu-sm" data-close="true">
				<i class="zmdi zmdi-arrow-left"></i>
				<i class="zmdi zmdi-arrow-right"></i>
			</a>
		</li>
		<li class="dropdown app_menu">
			<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"><i class="zmdi zmdi-apps"></i></a>
			<ul class="dropdown-menu pullDown">
				<li>
					<a routerLink="/player/communication/tickets"><i class="zmdi zmdi-email m-r-10"></i><span>Mail</span></a>
				</li>
				<!--<li><a routerLink="/app/contact"><i class="zmdi zmdi-accounts-list m-r-10"></i><span>Contacts</span></a>-->
				<!--</li>-->
				<!--<li><a routerLink="/app/chat"><i class="zmdi zmdi-comment-text m-r-10"></i><span>Chat</span></a></li>-->
				<!--<li>-->
				<!--<a routerLink="/sample-page/invoices"><i class="zmdi zmdi-arrows m-r-10"></i><span>Invoices</span></a>-->
				<!--</li>-->
				<!--<li>-->
				<!--<a routerLink="/app/calendar"><i class="zmdi zmdi-calendar-note m-r-10"></i><span>Calendar</span></a>-->
				<!--</li>-->
				<!--<li><a href="javascript:void(0)"><i class="zmdi zmdi-arrows m-r-10"></i><span>Notes</span></a></li>-->
				<!--<li><a href="javascript:void(0)"><i class="zmdi zmdi-view-column m-r-10"></i><span>Taskboard</span></a>-->
				<!--</li>-->
			</ul>
		</li>

		<li class="dropdown notifications hidden-sm-down">
			<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"><i class="zmdi zmdi-notifications"></i>
				<span class="label-count">0</span>
			</a>
			<ul class="dropdown-menu pullDown">
				<li class="body">
					<ul class="menu list-unstyled">
						<!--<li>-->
						<!--<a href="javascript:void(0);">-->
						<!--<div class="media">-->
						<!--<img class="media-object" src="assets/images/xs/avatar5.jpg" alt="">-->
						<!--<div class="media-body">-->
						<!--<span class="name">Alexander <span class="time">35min ago</span></span>-->
						<!--<span class="message">Contrary to popular belief, Lorem Ipsum random</span>-->
						<!--</div>-->
						<!--</div>-->
						<!--</a>-->
						<!--</li>-->
						<!--<li>-->
						<!--<a href="javascript:void(0);">-->
						<!--<div class="media">-->
						<!--<img class="media-object" src="assets/images/xs/avatar6.jpg" alt="">-->
						<!--<div class="media-body">-->
						<!--<span class="name">Grayson <span class="time">1hr ago</span></span>-->
						<!--<span class="message">There are many variations of passages</span>-->
						<!--</div>-->
						<!--</div>-->
						<!--</a>-->
						<!--</li>-->
						<!--<li>-->
						<!--<a href="javascript:void(0);">-->
						<!--<div class="media">-->
						<!--<img class="media-object" src="assets/images/xs/avatar3.jpg" alt="">-->
						<!--<div class="media-body">-->
						<!--<span class="name">Sophia <span class="time">31min ago</span></span>-->
						<!--<span class="message">There are many variations of passages of Lorem Ipsum</span>-->
						<!--</div>-->
						<!--</div>-->
						<!--</a>-->
						<!--</li>-->
						<!--<li>-->
						<!--<a class="alert alert-warning m-b-0" href="javascript:void(0);">-->
						<!--<img class="float-right rounded-circle dropdown-avatar" src="assets/images/xs/avatar10.jpg" alt="">-->
						<!--<strong>Alex Anistor</strong><br>-->
						<!--<small>Testing functionality...</small>-->
						<!--</a>-->
						<!--</li>-->
						<!--<li>-->
						<!--<a href="javascript:void(0);">-->
						<!--<div class="media">-->
						<!--<img class="media-object" src="assets/images/xs/avatar4.jpg" alt="">-->
						<!--<div class="media-body">-->
						<!--<span class="name">Isabella <span class="time">35min ago</span></span>-->
						<!--<span class="message">There are many variations of passages</span>-->
						<!--</div>-->
						<!--</div>-->
						<!--</a>-->
						<!--</li>-->
						<!--<li>-->
						<!--<a class="alert alert-info m-b-0" href="javascript:void(0);">-->
						<!--<img class="float-right rounded-circle dropdown-avatar" src="assets/images/xs/avatar9.jpg" alt="">-->
						<!--<strong>Alicia Adell</strong><br>-->
						<!--<small>Reviewing last changes...</small>-->
						<!--</a>-->
						<!--</li>-->
					</ul>
				</li>
				<li class="footer"><a href="javascript:void(0);">All good</a></li>
			</ul>
		</li>


		<!--<li class="dropdown task">-->
		<!--<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"><i class="zmdi zmdi-flag"></i>-->
		<!--<span class="label-count">3</span>-->
		<!--</a>-->
		<!--<ul class="dropdown-menu pullDown">-->
		<!--<li class="header">Project</li>-->
		<!--<li class="body">-->
		<!--<ul class="menu tasks list-unstyled">-->
		<!--<li>-->
		<!--<a href="javascript:void(0);">-->
		<!--<span class="text-muted">Project 1 <span class="float-right">29%</span></span>-->
		<!--<div class="progress">-->
		<!--<div class="progress-bar l-turquoise" role="progressbar" aria-valuenow="29" aria-valuemin="0" aria-valuemax="100" style="width: 29%;"></div>-->
		<!--</div>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li>-->
		<!--<a href="javascript:void(0);">-->
		<!--<span class="text-muted">Project 2 <span class="float-right">78%</span></span>-->
		<!--<div class="progress">-->
		<!--<div class="progress-bar l-slategray" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width: 78%;"></div>-->
		<!--</div>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li>-->
		<!--<a href="javascript:void(0);">-->
		<!--<span class="text-muted">Project 3 <span class="float-right">45%</span></span>-->
		<!--<div class="progress">-->
		<!--<div class="progress-bar l-parpl" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 45%;"></div>-->
		<!--</div>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li>-->
		<!--<a href="javascript:void(0);">-->
		<!--<span class="text-muted">Project 4 <span class="float-right">68%</span></span>-->
		<!--<div class="progress">-->
		<!--<div class="progress-bar l-coral" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" style="width: 68%;"></div>-->
		<!--</div>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li>-->
		<!--<a href="javascript:void(0);">-->
		<!--<span class="text-muted">Project 5 <span class="float-right">21%</span></span>-->
		<!--<div class="progress">-->
		<!--<div class="progress-bar l-amber" role="progressbar" aria-valuenow="21" aria-valuemin="0" aria-valuemax="100" style="width: 21%;"></div>-->
		<!--</div>-->
		<!--</a>-->
		<!--</li>-->
		<!--</ul>-->
		<!--</li>-->
		<!--<li class="footer"><a href="javascript:void(0);">View All</a></li>-->
		<!--</ul>-->
		<!--</li>-->
		<li class="search_bar hidden-sm-down">


			<div class="input-group">
				<input type="text" class="form-control" placeholder="Search...">
				<span class="input-group-addon">
                    <i class="zmdi zmdi-search"></i>
                </span>
			</div>
		</li>
		<li class="float-right">
			<a href="javascript:void(0);" class="fullscreen hidden-md-down hidden-sm-down" data-provide="fullscreen"><i class="zmdi zmdi-fullscreen"></i></a>
			<!--<a href="javascript:void(0);" class="js-right-chat"><i class="zmdi zmdi-comments"></i></a>-->
			<a href="javascript:void(0);" class="js-right-sidebar"><i class="zmdi zmdi-settings"></i></a>
			<a href="javascript:void(0);" class="btn_overlay hidden-sm-down"><i class="zmdi zmdi-sort-amount-desc"></i></a>

			<a href="javascript:void(0);" title="Connectivity Status">
				<span
					[ngStyle]="{'color':(config.connectionStatus |async) ? '#18ce0f' : '#FF3636' }">
					<i
						[ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'"
						class="zmdi zmdi-wifi-alt">
					</i>
				</span>
			</a>

			<a style="" class="demoClass" href="javascript:void(0);">
				<ui-switch
					[checked]="(config.offlineModeDisabled |async )"
					[disabled]="(config.offlineModeUnavailable |async )"
					(change)="offlineSwitchChange($event)"
					checkedLabel="I"
					uncheckedLabel="O"
					color="#99CC99"
					switchColor=""
					defaultBgColor="#FFCCCC"
					defaultBoColor=""
					size="small"
					style="color:black;">
				</ui-switch>
			</a>


			<a href="javascript:void(0);" (click)="logoutUser()" class="mega-menu"><i class="zmdi zmdi-power"></i></a>
		</li>
	</ul>
</nav>


<!-- Left Sidebar -->
<div ngSwitch="{{previousUrl}}">
	<div *ngSwitchDefault>
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>
						<div class="user-info m-b-20">
							<div class="image">
								<a routerLink="/sample-pages/profile"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{adminName}}</h4>
								<p class="m-b-0">{{adminTitle}}</p>
								<!--<a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>-->
								<a routerLink="/player/communication/tickets" title="Inbox"><i class="zmdi zmdi-email"></i></a>

								<p>
									<span style="display: inline-flex; align-items: center;" [ngClass]="(config.connectionStatus |async )?'badge-success':'badge-danger'" class="badge">
										<i style="margin-right: 4px" [ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'" class="zmdi"></i>{{(config.connectionStatus |async )?'Connected' : 'Disconnected'}}
									</span>
								</p>

								<!--<a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>-->


								<!--div class="row">
									<div class="col-12 small"><p>Month-to-date</p></div>
									<div class="col-4 p-r-0">
										<h6 class="m-b-5">54</h6>
										<small>Deposits</small>
									</div>
									<div class="col-4 p-l-0 p-r-0">
										<h6 class="m-b-5">12</h6>
										<small>Cashouts</small>
									</div>
									<div class="col-4 p-l-0">
										<h6 class="m-b-5">$134K</h6>
										<small>GGR</small>
									</div>
								</div-->


							</div>
						</div>
					</li>


					<li class="header">Base</li>
					<li routerLinkActive="active open">
						<a routerLink="/dashboard/main"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a></li>


					<!--<div>-->
					<!--<li class="header">Internal Affairs</li>-->
					<!--<li *ngFor="let key of permissions | flatObject; let i = index" routerLinkActive="active open">-->
					<!--<a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-assignment"></i><span>{{key}}</span></a>-->
					<!--<ul class="ml-menu">-->
					<!--<li *ngFor="let sub_key of permissions[key] | flatObject;" routerLinkActive="active">-->
					<!--<a routerLink="/{{key|lowercase }}/{{sub_key | lowercase }}">{{sub_key}}</a></li>-->
					<!--</ul>-->
					<!--</li>-->

					<!--</div>-->


					<div *ngFor="let permission of routingModel | flatObject">
						<li class="header">{{permission}}</li>

						<li *ngFor="let sub_permission of routingModel[permission] | flatObject" routerLinkActive="active open">
							<a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-assignment"></i><span>{{sub_permission}}</span></a>
							<ul class="ml-menu">
								<li *ngFor="let sub_sub_permission of routingModel[permission][sub_permission] | flatObject;" routerLinkActive="active">
									<a routerLink="/{{permission|lowercase}}/{{sub_permission|lowercase }}/{{sub_sub_permission | lowercase }}">{{sub_sub_permission}}</a>
								</li>
							</ul>
						</li>
					</div>


					<li class="header">Glossary</li>
					<li><a routerLink="/overview/docs"><i class="zmdi zmdi-circle-o text-warning"></i><span>Documentation</span></a>
					</li>
					<li><a routerLink="/overview/faqs"><i class="zmdi zmdi-circle-o text-success"></i><span>Faqs</span></a>
					</li>
				</ul>
			</div>
		</aside>
	</div>

	<div *ngSwitchCase="'horizontal'">
		<div class="menu-container">
			<div class="menu">
				<ul class="pullDown">
					<li><a routerLink="/dashboard/main">Dashboard</a></li>
					<li><a href="javascript:void(0)">Layouts</a>
						<ul class="pullDown">
							<li><a routerLink="/dashboard/rtl">RTL Layouts</a></li>
							<li><a routerLink="/dashboard/horizontal">Horizontal Menu</a></li>
							<li><a routerLink="/dashboard/small-menu">Small leftmenu</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">App</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Inbox</a></li>
							<li><a href="javascript:void(0)">Chat</a></li>
							<li><a href="javascript:void(0)">Calendar</a></li>
							<li><a href="javascript:void(0)">Contact list</a></li>
							<li><a href="javascript:void(0)">Blogger</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">UI Kit</a>
						<ul class="pullDown">
							<li><span><i class="zmdi zmdi-label"></i> List</span>
								<ul>
									<li><a routerLink="/user-interface/ui-kit">UI KIT</a></li>
									<li><a routerLink="/user-interface/alerts">Alerts</a></li>
									<li><a routerLink="/user-interface/collapsed">Collapse</a></li>
									<li><a routerLink="/user-interface/colors">Colors</a></li>
								</ul>
							</li>
							<li><span><i class="zmdi zmdi-label"></i> List</span>
								<ul>
									<li><a routerLink="/user-interface/dialogs">Dialogs</a></li>
									<li><a routerLink="/user-interface/icons">Icons</a></li>
									<li><a routerLink="/user-interface/list-group">List Group</a></li>
									<li><a routerLink="/user-interface/media-object">Media Object</a></li>
								</ul>
							</li>
							<li><span><i class="zmdi zmdi-label"></i> List</span>
								<ul>
									<li><a routerLink="/user-interface/modals">Modals</a></li>
									<li><a routerLink="/user-interface/notifications">Notifications</a></li>
									<li><a routerLink="/user-interface/progressbars">Progress Bars</a></li>
									<li><a routerLink="/user-interface/range-sliders">Range Sliders</a></li>
								</ul>
							</li>
							<li><span><i class="zmdi zmdi-label"></i> List</span>
								<ul>
									<li><a routerLink="/user-interface/sortable-nestable">Sortable &amp; Nestable</a>
									</li>
									<li><a routerLink="/user-interface/tabs">Tabs</a></li>
									<li><a routerLink="/user-interface/waves">Waves</a></li>
								</ul>
							</li>
						</ul>
					</li>
					<li><a href="javascript:void(0)"><i class="zmdi zmdi-assignment"></i> Forms</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Basic Elements</a></li>
							<li><a href="javascript:void(0)">Advanced Elements</a></li>
							<li><a href="javascript:void(0)">Form Examples</a></li>
							<li><a href="javascript:void(0)">Form Validation</a></li>
							<li><a href="javascript:void(0)">Form Wizard</a></li>
							<li><a href="javascript:void(0)">Editors</a></li>
							<li><a href="javascript:void(0)">File Upload</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">Tables</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Normal Tables</a></li>
							<li><a href="javascript:void(0)">Jquery Datatables</a></li>
							<li><a href="javascript:void(0)">Editable Tables</a></li>
							<li><a href="javascript:void(0)">Foo Tables</a></li>
							<li><a href="javascript:void(0)">Tables Color</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)"><i class="zmdi zmdi-chart"></i> Charts</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Morris</a></li>
							<li><a href="javascript:void(0)">Flot</a></li>
							<li><a href="javascript:void(0)">ChartJS</a></li>
							<li><a href="javascript:void(0)">Sparkline</a></li>
							<li><a href="javascript:void(0)">Jquery Knob</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">Widgets</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">App Widgets</a></li>
							<li><a href="javascript:void(0)">Data Widgets</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">Authentication</a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Sign In</a></li>
							<li><a href="javascript:void(0)">Sign Up</a></li>
							<li><a href="javascript:void(0)">Forgot Password</a></li>
							<li><a href="javascript:void(0)">Page 404</a></li>
							<li><a href="javascript:void(0)">Page 500</a></li>
							<li><a href="javascript:void(0)">Page Offline</a></li>
							<li><a href="javascript:void(0)">Locked Screen</a></li>
						</ul>
					</li>
					<li><a href="javascript:void(0)">Extra </a>
						<ul class="pullDown">
							<li><a href="javascript:void(0)">Blank Page</a></li>
							<li><a href="javascript:void(0)">Image Gallery</a></li>
							<li><a href="javascript:void(0)">Profile</a></li>
							<li><a href="javascript:void(0)">Timeline</a></li>
							<li><a href="javascript:void(0)">Pricing</a></li>
							<li><a href="javascript:void(0)">Invoices</a></li>
							<li><a href="javascript:void(0)">Search Results</a></li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="previousUrl == 'docs' || previousUrl == 'docs#documenter_cover'|| previousUrl == 'docs#overview'|| previousUrl == 'docs#features'|| previousUrl == 'docs#template'|| previousUrl == 'docs#main_menu'|| previousUrl == 'docs#grunt_file'|| previousUrl == 'docs#main_content'|| previousUrl == 'docs#folder_structure'|| previousUrl == 'docs#css'|| previousUrl == 'docs#javascript'|| previousUrl == 'docs#fonts'|| previousUrl == 'docs#thank_you' ? previousUrl : ''">
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>


						<div class="user-info m-b-20">
							<div class="image">
								<a routerLink="/sample-pages/profile"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{adminName}}</h4>
								<p class="m-b-0">{{adminTitle}}</p>
								<!--<a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>-->
								<a routerLink="/player/communication/tickets" title="Inbox"><i class="zmdi zmdi-email"></i></a>

								<p>
									<span style="display: inline-flex; align-items: center;" [ngClass]="(config.connectionStatus |async )?'badge-success':'badge-danger'" class="badge">
										<i style="margin-right: 4px" [ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'" class="zmdi"></i>{{(config.connectionStatus |async )?'Connected' : 'Disconnected'}}
									</span>
								</p>
								<!--<a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>-->


								<!--		<div class="row">
											<div class="col-12 small"><p>Month-to-date</p></div>
											<div class="col-4 p-r-0">
												<h6 class="m-b-5">54</h6>
												<small>Deposits</small>
											</div>
											<div class="col-4 p-l-0 p-r-0">
												<h6 class="m-b-5">12</h6>
												<small>Cashouts</small>
											</div>
											<div class="col-4 p-l-0">
												<h6 class="m-b-5">$134K</h6>
												<small>GGR</small>
											</div>
										</div>-->


							</div>
						</div>
					</li>
					<li class="header">MAIN NAVIGATION</li>
					<li><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a></li>
					<li><a routerLink="/overview/docs" fragment="documenter_cover"><i class="zmdi zmdi-arrow-right"></i><span>Start </span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="overview"><i class="zmdi zmdi-arrow-right"></i><span>Overview</span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="features"><i class="zmdi zmdi-arrow-right"></i><span>Features</span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="template"><i class="zmdi zmdi-arrow-right"></i><span>Template</span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="main_menu"><i class="zmdi zmdi-arrow-right"></i><span>Main Menu</span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="grunt_file"><i class="zmdi zmdi-arrow-right"></i><span>Grunt File</span></a>
					</li>
					<li>
						<a routerLink="/overview/docs" fragment="main_content"><i class="zmdi zmdi-arrow-right"></i><span>Main Content</span></a>
					</li>
					<li><a routerLink="/overview/docs" fragment="folder_structure"><i class="zmdi zmdi-arrow-right"></i><span>Folder Structure</span></a>
					</li>
					<li>
						<a routerLink="/overview/docs" fragment="css"><i class="zmdi zmdi-arrow-right"></i><span>CSS</span></a>
					</li>
				</ul>
			</div>
		</aside>
	</div>

	<div *ngSwitchCase="previousUrl == 'file-dashboard' || previousUrl == 'file-documents' || previousUrl ==  'file-media' || previousUrl ==  'file-images' ? previousUrl : ''">
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>

						<div class="user-info m-b-20">
							<div class="image">
								<a routerLink="/sample-pages/profile"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{adminName}}</h4>
								<p class="m-b-0">{{adminTitle}}</p>
								<!--<a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>-->
								<a routerLink="/player/communication/tickets" title="Inbox"><i class="zmdi zmdi-email"></i></a>

								<p>
									<span style="display: inline-flex; align-items: center;" [ngClass]="(config.connectionStatus |async )?'badge-success':'badge-danger'" class="badge">
										<i style="margin-right: 4px" [ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'" class="zmdi"></i>{{(config.connectionStatus |async )?'Connected' : 'Disconnected'}}
									</span>
								</p>
								<!--<a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>-->


								<!--div class="row">
									<div class="col-12 small"><p>Month-to-date</p></div>
									<div class="col-4 p-r-0">
										<h6 class="m-b-5">54</h6>
										<small>Deposits</small>
									</div>
									<div class="col-4 p-l-0 p-r-0">
										<h6 class="m-b-5">12</h6>
										<small>Cashouts</small>
									</div>
									<div class="col-4 p-l-0">
										<h6 class="m-b-5">$134K</h6>
										<small>GGR</small>
									</div>
								</div-->


							</div>
						</div>
					</li>
					<li class="header">MAIN NAVIGATION</li>
					<li><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a></li>
					<li routerLinkActive="active open">
						<a routerLink="/app/file-dashboard"><i class="zmdi zmdi-layers"></i><span>All File</span></a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/file-documents"><i class="zmdi zmdi-collection-text"></i><span>Documents</span></a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/file-media"><i class="zmdi zmdi-collection-video"></i><span>Media</span></a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/file-images"><i class="zmdi zmdi-collection-image"></i><span>Images</span></a>
					</li>
					<li class="header">FOLDERS</li>
					<li>
						<a href="javascript:void(0);"><i class="zmdi zmdi-folder-star"></i><span>My Collection</span></a>
					</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-folder"></i><span>Pictures</span></a></li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-folder"></i><span>Books</span></a></li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-folder"></i><span>Video</span></a></li>
					<li class="header">LABELS</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-circle-o col-blue"></i><span>Family</span></a>
					</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-circle-o col-green"></i><span>Work</span></a>
					</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-circle-o col-amber"></i><span>Holidays</span></a>
					</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-circle-o col-blush"></i><span>Music</span></a>
					</li>
					<li><a href="javascript:void(0);"><i class="zmdi zmdi-circle-o col-red"></i><span>Photography</span></a>
					</li>
				</ul>
			</div>
		</aside>
	</div>

	<div *ngSwitchCase="previousUrl == 'inbox' || previousUrl == 'compose-mail' || previousUrl ==  'single-mail' ? previousUrl : ''">
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>
						<div class="user-info m-b-20">
							<div class="image">
								<a routerLink="/sample-pages/profile"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{adminName}}</h4>
								<p class="m-b-0">{{adminTitle}}</p>
								<!--<a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>-->
								<a routerLink="/player/communication/tickets" title="Inbox"><i class="zmdi zmdi-email"></i></a>

								<p>
									<span style="display: inline-flex; align-items: center;" [ngClass]="(config.connectionStatus |async )?'badge-success':'badge-danger'" class="badge">
										<i style="margin-right: 4px" [ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'" class="zmdi"></i>{{(config.connectionStatus |async )?'Connected' : 'Disconnected'}}
									</span>
								</p>
								<!--<a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>-->
								<div class="row">
									<div class="col-6">
										<small>Primary</small>
										<h5 class="m-b-5 number count-to" data-from="0" data-to="234" data-speed="2000" data-fresh-interval="700">
											234</h5>
									</div>
									<div class="col-6">
										<small>Updates</small>
										<h5 class="m-b-5 number count-to" data-from="0" data-to="705" data-speed="2000" data-fresh-interval="700">
											705</h5>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="header">MAIN NAVIGATION</li>
					<li routerLinkActive="active open">
						<a routerLink="/dashboard">
							<i class="zmdi zmdi-home"></i>
							<span>Dashboard</span>
						</a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/inbox">
							<i class="zmdi zmdi-email"></i>
							<span>Inbox</span>
						</a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/compose-mail">
							<i class="zmdi zmdi-plus-circle"></i>
							<span>Compose Mail</span>
						</a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/single-mail">
							<i class="zmdi zmdi-file-text"></i>
							<span>Single Mail</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-folder-star"></i>
							<span>Draft</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-folder-star"></i>
							<span>Important</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-delete"></i>
							<span>Bin</span>
						</a>
					</li>
					<li class="header">LABELS</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-circle col-blue"></i>
							<span>Family</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-circle col-green"></i>
							<span>Work</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-circle col-amber"></i>
							<span>Shop</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-circle col-blush"></i>
							<span>Themeforest</span>
						</a>
					</li>
					<li>
						<a href="javascript:void(0);">
							<i class="zmdi zmdi-circle col-red"></i>
							<span>Google</span>
						</a>
					</li>
				</ul>
			</div>
		</aside>
	</div>

	<div *ngSwitchCase="previousUrl == 'blog-dashboard' || previousUrl == 'blog-post' || previousUrl ==  'blog-list' || previousUrl ==  'blog-grid' || previousUrl ==  'blog-details' ? previousUrl : ''">
		<aside id="leftsidebar" class="sidebar">
			<div class="menu">
				<ul class="list">
					<li>
						<div class="user-info m-b-20">
							<div class="image">
								<a routerLink="/sample-pages/profile"><img src="assets/Icons/favicon_package/android-chrome-192x192.png" alt="User"></a>
							</div>
							<div class="detail">
								<h4>{{adminName}}</h4>
								<p class="m-b-0">{{adminTitle}}</p>
								<!--<a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>-->
								<a routerLink="/player/communication/tickets" title="Inbox"><i class="zmdi zmdi-email"></i></a>

								<p>
									<span style="display: inline-flex; align-items: center;" [ngClass]="(config.connectionStatus |async )?'badge-success':'badge-danger'" class="badge">
										<i style="margin-right: 4px" [ngClass]="(config.connectionStatus |async )?'zmdi-wifi-alt':'zmdi-wifi-off'" class="zmdi"></i>{{(config.connectionStatus |async )?'Connected' : 'Disconnected'}}
									</span>
								</p>
								<!--<a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>-->


								<!--div class="row">
									<div class="col-12 small"><p>Month-to-date</p></div>
									<div class="col-4 p-r-0">
										<h6 class="m-b-5">54</h6>
										<small>Deposits</small>
									</div>
									<div class="col-4 p-l-0 p-r-0">
										<h6 class="m-b-5">12</h6>
										<small>Cashouts</small>
									</div>
									<div class="col-4 p-l-0">
										<h6 class="m-b-5">$134K</h6>
										<small>GGR</small>
									</div>
								</div-->


							</div>
						</div>

					</li>
					<li class="header">MAIN</li>
					<li><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i><span>Home</span> </a></li>
					<li routerLinkActive="active open">
						<a routerLink="/app/blog-dashboard"><i class="zmdi zmdi-blogger"></i><span>Blog Dashboard</span>
						</a></li>
					<li routerLinkActive="active open">
						<a routerLink="/app/blog-post"><i class="zmdi zmdi-plus-circle"></i><span>New Post</span> </a>
					</li>
					<li routerLinkActive="active open">
						<a routerLink="/app/blog-list"><i class="zmdi zmdi-sort-amount-desc"></i><span>Blog List</span>
						</a></li>
					<li routerLinkActive="active open">
						<a routerLink="/app/blog-grid"><i class="zmdi zmdi-grid"></i><span>Blog Grid</span> </a></li>
					<li routerLinkActive="active open">
						<a routerLink="/app/blog-details"><i class="zmdi zmdi-label-alt"></i><span>Blog Single</span>
						</a></li>
					<li class="header">CATEGORIES</li>
					<li>
						<a href="javascript:void(0);" class="waves-effect waves-block"><i class="zmdi zmdi-circle-o col-green"></i><span>Web Design</span></a>
					</li>
					<li>
						<a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-circle-o col-red"></i><span>Photography</span>
						</a>
						<ul class="ml-menu">
							<li><a href="javascript:void(0);">Wild</a></li>
							<li><a href="javascript:void(0);">Marriage</a></li>
						</ul>
					</li>
					<li>
						<a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-circle-o col-amber"></i><span>Technology</span>
						</a>
						<ul class="ml-menu">
							<li><a href="javascript:void(0);">UI UX Design</a></li>
							<li><a href="javascript:void(0);">Android</a></li>
							<li><a href="javascript:void(0);">iOS</a></li>
							<li><a href="javascript:void(0);">Wordpress</a></li>
						</ul>
					</li>
					<li>
						<a href="javascript:void(0);" class="waves-effect waves-block"><i class="zmdi zmdi-circle-o col-purple"></i><span>Lifestyle</span></a>
					</li>
					<li>
						<a href="javascript:void(0);" class="waves-effect waves-block"><i class="zmdi zmdi-circle-o col-lime"></i><span>Sports</span></a>
					</li>
				</ul>
			</div>
		</aside>
	</div>

	<div *ngSwitchCase="previousUrl == 'sign-in' || previousUrl == 'sign-up' || previousUrl ==  'forgot-password' || previousUrl ==  '404' || previousUrl ==  '500' || previousUrl ==  'offline' || previousUrl ==  'locked' ? previousUrl : '' "></div>
</div>


<!--<div *ngIf="previousUrl; else elseBlock"></div>-->


<!--<ng-template #elseBlock>
    <aside id="leftsidebar" class="sidebar">
        <div class="menu">
            <ul class="list">
                <li>
                    <div class="user-info m-b-20">
                        <div class="image"><a routerLink="/sample-pages/profile"><img src="assets/images/profile_av.jpg" alt="User"></a></div>
                        <div class="detail">
                            <h4>Michael</h4>
                            <p class="m-b-0">Manager</p>
                            <a routerLink="/app/calendar" title="Events"><i class="zmdi zmdi-calendar"></i></a>
                            <a routerLink="/app/inbox" title="Inbox"><i class="zmdi zmdi-email"></i></a>
                            <a routerLink="/app/contact" title="Contact List"><i class="zmdi zmdi-account-box-phone"></i></a>
                            <div class="row">
                                <div class="col-4 p-r-0">
                                    <h6 class="m-b-5">852</h6>
                                    <small>Sales</small>
                                </div>
                                <div class="col-4 p-l-0 p-r-0">
                                    <h6 class="m-b-5">513</h6>
                                    <small>Order</small>
                                </div>
                                <div class="col-4 p-l-0">
                                    <h6 class="m-b-5">$34M</h6>
                                    <small>Revenue</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header">MAIN</li>
                <li routerLinkActive="active open"> <a routerLink="/dashboard/main"><i class="zmdi zmdi-home"></i><span>Dashboard</span></a></li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-gamepad"></i><span>Layouts Format</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/dashboard/rtl">RTL Layouts</a></li>
                        <li routerLinkActive="active"><a routerLink="/dashboard/horizontal">Horizontal Menu</a></li>
                        <li routerLinkActive="active"><a routerLink="/dashboard/small-menu">Small leftmenu</a></li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-apps"></i><span>App</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/app/inbox">Inbox</a></li>
                        <li routerLinkActive="active"><a routerLink="/app/chat">Chat</a></li>
                        <li routerLinkActive="active"><a routerLink="/app/calendar">Calendar</a></li>
                        <li routerLinkActive="active"><a routerLink="/app/file-dashboard">File Manager</a></li>
                        <li routerLinkActive="active"><a routerLink="/app/contact">Contact list</a></li>
                        <li routerLinkActive="active"><a routerLink="/app/blog-dashboard">Blog</a></li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-swap-alt"></i><span>User Interface (UI)</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/user-interface/ui-kit">UI KIT</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/alerts">Alerts</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/collapsed">Collapse</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/colors">Colors</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/dialogs">Dialogs</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/icons">Icons</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/list-group">List Group</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/media-object">Media Object</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/modals">Modals</a> </li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/notifications">Notifications</a></li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/progressbars">Progress Bars</a></li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/range-sliders">Range Sliders</a></li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/sortable-nestable">Sortable & Nestable</a></li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/tabs">Tabs</a></li>
                        <li routerLinkActive="active"><a routerLink="/user-interface/waves">Waves</a></li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-widgets"></i><span>Widgets</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/widgets/widgets-app">Apps Widgetse</a></li>
                        <li routerLinkActive="active"><a routerLink="/widgets/widgets-data">Data Widgetse</a></li>
                    </ul>
                </li>
                <li class="header">FORMS, CHARTS, TABLES</li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-assignment"></i><span>Forms</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/forms/basic-elements">Basic Elements</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/advanced-elements">Advanced Elements</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/form-examples">Form Examples</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/form-validation">Form Validation</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/form-wizard">Form Wizard</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/form-editors">Editors</a> </li>
                        <li routerLinkActive="active"><a routerLink="/forms/form-upload">File Upload</a></li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-grid"></i><span>Tables</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/tables/normal-tables">Normal Tables</a> </li>
                        <li routerLinkActive="active"><a routerLink="/tables/jquery-datatable">Jquery Datatables</a> </li>
                        <li routerLinkActive="active"><a routerLink="/tables/editable-table">Editable Tables</a> </li>
                        <li routerLinkActive="active"><a routerLink="/tables/footable">Foo Tables</a> </li>
                        <li routerLinkActive="active"><a routerLink="/tables/table-color">Tables Color</a> </li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-chart"></i><span>Charts</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/charts/morris">Morris</a> </li>
                        <li routerLinkActive="active"><a routerLink="/charts/flot">Flot</a> </li>
                        <li routerLinkActive="active"><a routerLink="/charts/chartjs">ChartJS</a> </li>
                        <li routerLinkActive="active"><a routerLink="/charts/sparklines">Sparkline</a> </li>
                        <li routerLinkActive="active"><a routerLink="/charts/jquery-knob">Jquery Knob</a> </li>
                    </ul>
                </li>
                <li class="header">EXTRA COMPONENTS</li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-map"></i><span>Maps</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/maps/google">Google Map</a></li>
                        &lt;!&ndash;<li routerLinkActive="active"><a routerLink="/maps/yandex">YandexMap</a></li>&ndash;&gt;
                        <li routerLinkActive="active"><a routerLink="/maps/jvectormap">jVectorMap</a></li>
                    </ul>
                </li>
                <li class="sm_menu_btm"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-lock"></i><span>Authentication</span></a>
                    <ul class="ml-menu">
                        <li><a routerLink="/authentication/sign-in">Sign In</a></li>
                        <li><a routerLink="/authentication/sign-up">Sign Up</a></li>
                        <li><a routerLink="/authentication/forgot-password">Forgot Password</a></li>
                        <li><a routerLink="/authentication/404">Page 404</a></li>
                        <li><a routerLink="/authentication/500">Page 500</a></li>
                        <li><a routerLink="/authentication/offline">Page Offline</a></li>
                        <li><a routerLink="/authentication/locked">Locked Screen</a></li>
                    </ul>
                </li>
                <li routerLinkActive="active open"><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-copy"></i><span>Sample Pages</span></a>
                    <ul class="ml-menu">
                        <li routerLinkActive="active"><a routerLink="/sample-pages/blank-page">Blank Page</a> </li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/image-gallery">Image Gallery</a> </li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/profile">Profile</a></li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/timeline">Timeline</a></li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/pricing">Pricing</a></li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/invoice">Invoices</a></li>
                        <li routerLinkActive="active"><a routerLink="/sample-pages/search-result">Search Results</a></li>
                    </ul>
                </li>
                <li><a routerLink="/overview/docs"><i class="zmdi zmdi-circle-o text-warning"></i><span>Documentation</span></a></li>
                <li><a routerLink="/overview/faqs"><i class="zmdi zmdi-circle-o text-success"></i><span>Faqs</span></a></li>
            </ul>
        </div>
    </aside>
</ng-template>-->
<!-- Right Chatbar -->
<aside id="rightchat" class="right_chat">
	<div class="chat_body">
		<div class="search">
			<div class="input-group">
				<input type="text" class="form-control" placeholder="Search...">
				<span class="input-group-addon">
                    <i class="zmdi zmdi-search"></i>
                </span>
			</div>
		</div>
		<ul class="chat-widget m-b-0 clearfix">
			<li class="left float-left">
				<div class="chat-info">
					<span class="datetime">6:12</span>
					<span class="message">Hello, John </span>
				</div>
			</li>
			<li class="right">
				<div class="chat-info">
					<a class="name" href="#">Alexander</a>
					<span class="datetime">6:15</span>
					<span class="message">Hi, Alexander<br> How are you!</span>
				</div>
			</li>
			<li class="right">
				<div class="chat-info">
					<a class="name" href="#">Alexander</a>
					<span class="datetime">6:16</span>
					<span class="message">There are many variations of passages of Lorem Ipsum available</span>
				</div>
			</li>
			<li class="left float-left">
				<div class="chat-info">
					<span class="datetime">6:25</span>
					<span class="message">Hi, Alexander,<br> John <br> What are you doing?</span>
				</div>
			</li>
			<li class="left float-left">
				<div class="chat-info">
					<span class="datetime">6:28</span>
					<span class="message">I would love to join the team.</span>
				</div>
			</li>
			<li class="right">
				<div class="chat-info">
					<span class="datetime">7:02</span>
					<span class="message">Hello, <br>Michael</span>
				</div>
			</li>
		</ul>
		<div class="enter_msg">
			<div class="input-group">
				<input type="text" class="form-control" placeholder="Enter text here...">
				<span class="input-group-addon"><i class="zmdi zmdi-mail-send"></i></span>
			</div>
		</div>
	</div>
	<div class="chat_list">
		<ul class="list-unstyled clearfix">
			<li><a href="#"><img src="assets/images/xs/avatar1.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar2.jpg" class="rounded-circle" alt=""></a></li>
			<li class="active"><a href="#"><img src="assets/images/xs/avatar3.jpg" class="rounded-circle" alt=""></a>
			</li>
			<li><a href="#"><img src="assets/images/xs/avatar4.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar5.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar6.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar7.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar8.jpg" class="rounded-circle" alt=""></a></li>
			<li><a href="#"><img src="assets/images/xs/avatar9.jpg" class="rounded-circle" alt=""></a></li>
		</ul>
	</div>
</aside>
<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar">
	<ul class="nav nav-tabs">
		<li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#setting">Setting</a></li>
		<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#activity">Activity</a></li>
	</ul>
	<div class="tab-content">
		<div class="tab-pane slideRight active" id="setting">
			<div class="slim_scroll">
				<div class="card">
					<h6>Skins</h6>
					<ul class="choose-skin list-unstyled">
						<li data-theme="purple">
							<div class="purple"></div>
						</li>
						<li data-theme="blue">
							<div class="blue"></div>
						</li>
						<li data-theme="cyan" class="active">
							<div class="cyan"></div>
						</li>
						<li data-theme="green">
							<div class="green"></div>
						</li>
						<li data-theme="orange">
							<div class="orange"></div>
						</li>
						<li data-theme="blush">
							<div class="blush"></div>
						</li>
					</ul>
				</div>
				<div class="card theme-light-dark">
					<h6>Left Menu</h6>
					<button class="t-light btn btn-default btn-simple btn-round">Light</button>
					<button class="t-dark btn btn-default btn-round">Dark</button>
				</div>
				<div class="card">
					<h6>General Settings</h6>
					<ul class="setting-list list-unstyled">
						<li>
							<div class="checkbox">
								<input id="checkbox1" type="checkbox">
								<label for="checkbox1">Report Panel Usage</label>
							</div>
						</li>
						<li>
							<div class="checkbox">
								<input id="checkbox2" type="checkbox" checked="">
								<label for="checkbox2">Email Redirect</label>
							</div>
						</li>
						<li>
							<div class="checkbox">
								<input id="checkbox3" type="checkbox" checked="">
								<label for="checkbox3">Notifications</label>
							</div>
						</li>
						<li>
							<div class="checkbox">
								<input id="checkbox4" type="checkbox" checked="">
								<label for="checkbox4">Auto Updates</label>
							</div>
						</li>
						<li>
							<div class="checkbox">
								<input id="checkbox5" type="checkbox" checked="">
								<label for="checkbox5">Offline</label>
							</div>
						</li>
						<li>
							<div class="checkbox">
								<input id="checkbox6" type="checkbox" checked="">
								<label for="checkbox6">Location Permission</label>
							</div>
						</li>
					</ul>
				</div>
				<div class="card">
					<h6>Information Summary</h6>
					<div class="row m-b-20">
						<div class="col-7">
							<small class="displayblock">MEMORY USAGE</small>
							<h5 class="m-b-0 h6">512</h5>
						</div>
						<div class="col-5">
							<div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#00ced1">
								8,7,9,5,6,4,6,8
							</div>
						</div>
					</div>
					<div class="row m-b-20">
						<div class="col-7">
							<small class="displayblock">CPU USAGE</small>
							<h5 class="m-b-0 h6">90%</h5>
						</div>
						<div class="col-5">
							<div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#F15F79">
								6,5,8,2,6,4,6,4
							</div>
						</div>
					</div>
					<div class="row m-b-20">
						<div class="col-7">
							<small class="displayblock">DAILY TRAFFIC</small>
							<h5 class="m-b-0 h6">25 142</h5>
						</div>
						<div class="col-5">
							<div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#78b83e">
								7,5,8,7,4,2,6,5
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-7">
							<small class="displayblock">DISK USAGE</small>
							<h5 class="m-b-0 h6">60.10%</h5>
						</div>
						<div class="col-5">
							<div class="sparkline" data-type="bar" data-width="97%" data-height="25px" data-bar-Width="5" data-bar-Spacing="3" data-bar-Color="#457fca">
								7,5,2,5,6,7,6,4
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tab-pane slideLeft" id="activity">
			<div class="slim_scroll">
				<div class="card user_activity">
					<h6>Recent Activity</h6>
					<div class="streamline b-accent">
						<div class="sl-item">
							<img class="user rounded-circle" src="assets/images/xs/avatar4.jpg" alt="">
							<div class="sl-content">
								<h5 class="m-b-0">Admin Birthday</h5>
								<small>Jan 21 <a href="javascript:void(0);" class="text-info">Sophia</a>.</small>
							</div>
						</div>
						<div class="sl-item">
							<img class="user rounded-circle" src="assets/images/xs/avatar5.jpg" alt="">
							<div class="sl-content">
								<h5 class="m-b-0">Add New Contact</h5>
								<small>30min ago <a href="javascript:void(0);">Alexander</a>.</small>
								<small><strong>P:</strong> +264-625-2323</small>
								<small><strong>E:</strong> maryamamiri@gmail.com</small>
							</div>
						</div>
						<div class="sl-item">
							<img class="user rounded-circle" src="assets/images/xs/avatar6.jpg" alt="">
							<div class="sl-content">
								<h5 class="m-b-0">Code Change</h5>
								<small>Today <a href="javascript:void(0);">Grayson</a>.</small>
								<small>The standard chunk of Lorem Ipsum used since the 1500s is reproduced</small>
							</div>
						</div>
						<div class="sl-item">
							<img class="user rounded-circle" src="assets/images/xs/avatar7.jpg" alt="">
							<div class="sl-content">
								<h5 class="m-b-0">New Email</h5>
								<small>45min ago <a href="javascript:void(0);" class="text-info">Fidel Tonn</a>.</small>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<h6>Recent Attachments</h6>
					<ul class="list-unstyled activity">
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-collection-pdf xl-slategray"></i>
								<div class="info">
									<h4>info_258.pdf</h4>
									<small>2MB</small>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-collection-text xl-slategray"></i>
								<div class="info">
									<h4>newdoc_214.doc</h4>
									<small>900KB</small>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-image xl-slategray"></i>
								<div class="info">
									<h4>MG_4145.jpg</h4>
									<small>5.6MB</small>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-image xl-slategray"></i>
								<div class="info">
									<h4>MG_4100.jpg</h4>
									<small>5MB</small>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-collection-text xl-slategray"></i>
								<div class="info">
									<h4>Reports_end.doc</h4>
									<small>780KB</small>
								</div>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)">
								<i class="zmdi zmdi-videocam xl-slategray"></i>
								<div class="info">
									<h4>movie2018.MKV</h4>
									<small>750MB</small>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</aside>

<router-outlet></router-outlet>
