import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './_services/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: 'app/navigation.module#NavigationModule',
        canActivate: [ AuthGuard ],
        data: {preload: false}
    },
    {
        path: 'authentication',
        loadChildren: 'app/authentication/authentication.module#AuthenticationModule'
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
        pathMatch: 'full'
    }
    // {
    //     path: 'dashboard',
    //     loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
    //     canActivate: [ AuthGuard ],
    //     data: {preload: false}
    // },
    // {
    //     path: 'app',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/app/app.module#AppModule'
    // },
    // {
    //     path: 'user-interface',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/user-interface/user-interface.module#UserInterfaceModule'
    // },
    // {
    //     path: 'widgets',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/widgets/widgets.module#WidgetsModule'
    // },
    // {
    //     path: 'forms',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/forms/forms.module#FormsModule'
    // },
    // {
    //     path: 'tables',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/tables/tables.module#TablesModule'
    // },
    // {
    //     path: 'charts',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/charts/charts.module#ChartsModule'
    // },
    // {
    //     path: 'maps',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/maps/maps.module#MapsModule'
    // },
    // {
    //     path: 'sample-pages',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/sample-pages/sample-pages.module#SamplePagesModule'
    // },
    // {
    //     path: 'overview',
    //     canActivate: [ AuthGuard ],
    //     loadChildren: 'app/overview/overview.module#OverviewModule'
    // }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
