import {Injectable} from '@angular/core';

@Injectable()
export class LoggerService {
    
    private loglevel = {
        'NONE': 0, // Bit 1
        'INFO': 2, // Bit 2
        'WARNINGS': 4, // Bit 3
        'ERRORS': 8, // Bit 4
        'ALWAYS': 16, // Bit 5
        'ALL': 30 // Bit 1 & 2 & 3 & 4 are all set
    };
    
    // private logmask = this.loglevel[ 'ALWAYS' ] | this.loglevel[ 'ERRORS' ];
    private logmask = this.loglevel[ 'ALL' ]; // | this.loglevel[ 'ALWAYS' ]; // additional flags this.loglevel[ 'ALL' ]
    
    private colors = {
        blue: '#73bbf1',
        red: '#f57e83',
        green: '#6bd396',
        purple: '#b8abf5',
        black: '#000000',
        darkred: '#da0c51',
        white: '#ffffff',
        demo1: '#32a081',
        demo2: '#f2e6b9',
        pink: '#f685c3',
        orange: '#ff6600'
    };
    
    private styles = {
        component: 'background-color: ' + this.colors.pink + '; color:' + this.colors.black + ';line-height:25px',
        error: 'background-color: ' + this.colors.darkred + '; color:' + this.colors.white + ';line-height:25px',
        intro: 'color: ' + this.colors.blue + ' ;  font-weight:bold',
        neutral: '',
        socketOpen: 'color: ' + this.colors.green + ' ;  font-weight:bold',
        socketMessage: 'color: ' + this.colors.purple + ' ;  font-weight:bold',
        socketClose: 'color: ' + this.colors.darkred + ' ;  font-weight:bold',
        warning: 'background-color: ' + this.colors.orange + '; color:' + this.colors.black + ';line-height:25px',
        information: 'background-color: ' + this.colors.blue + '; color:' + this.colors.black + ';line-height:25px',
        success: 'background-color: ' + this.colors.green + '; color:' + this.colors.black + ';line-height:25px',
    };
    
    constructor() {
    }
    
    c(output: any, style: string, loglevel: number) {
        if (!!(this.logmask & loglevel)) {
            !!style ? console.log('%c' + output, this.styles[ style ]) : console.log(output);
        }
    }
    
    g(output: any, style: string, loglevel: number) {
        if (!!(this.logmask & loglevel)) {
            !!style ? console.group('%c' + output, this.styles[ style ]) : console.group(output);
        }
    }
    
    gE() {
        console.groupEnd();
    }
    
    style(output: any, style: string) {
        !!style ? console.log('%c' + output, this.styles[ style ]) : console.log(output);
    }
    
    plain(output: any) {
        // console.group();
        console.log(output);
        // console.groupEnd();
    }
    
    image(url: string) {
        console.log('%c       ', 'font-size: 90px; background:url(' + url + ') no-repeat;');
    }
}
