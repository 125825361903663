// +----------------------------------------------------+
// | imports                                            |
// +----------------------------------------------------+
import {Component, DoCheck, OnInit, ViewEncapsulation, IterableDiffers, OnChanges, Inject, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../_services/api.service';
import {Observable, Subscription, interval} from 'rxjs';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../_services/logger.service';

declare const $: any;

export class Partner {
    public name?: string;
    public id?: number;
}

export class Currency {
    public name?: string;
    public id?: number;
}

@Component({
    selector: 'app-ggr',
    templateUrl: './ggr.component.html',
    styleUrls: [ './ggr.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class GgrComponent implements OnInit, DoCheck, OnChanges, AfterViewInit {
    // +----------------------------------------------------+
    // | component properties                               |
    // +----------------------------------------------------+
    urlSegments: Array<string> = [];
    public i: any;
    searchForm: FormGroup;
    formerrors: string;
    GGR: Array<Array<string>> = [];
    differ: any;
    public currencies: Array<Currency> = [
        {name: 'Euro', id: 6},
        {name: 'US Dollar', id: 1}
    ];
    public partnerIDs: Array<Partner> = [];
    private poll$: Subscription;
    private today: string = moment().subtract(1, 'days').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss');
    private yesterday: string = moment().subtract(2, 'days').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss');
    
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    constructor(private api: ApiService,
                private differs: IterableDiffers,
                private fb: FormBuilder,
                @Inject('LOCALSTORAGE') private localStorage: any,
                public route: ActivatedRoute,
                private translate: TranslateService,
                private log: LoggerService) {
        translate.setDefaultLang('en');
        translate.use(translate.getBrowserLang());
        this.parseUrlSegments();
        this.differ = differs.find([]).create(null);
        this.createForm();
        const casinos = JSON.parse(this.localStorage.userData).casinos;
        
        
        for (const key in casinos) {
            if (casinos.hasOwnProperty(key)) {
                let partnerUnit;
                if (casinos[ key ] === 'Default') {
                    partnerUnit = {name: casinos[ key ], id: 'null'};
                } else {
                    partnerUnit = {name: casinos[ key ], id: Number(key)};
                }
                this.partnerIDs.push(partnerUnit);
            }
        }
    }
    
    // +----------------------------------------------------+
    // | methods                                            |
    // +----------------------------------------------------+
    ngDoCheck() {
        // const change = this.differ.diff(this.GGR);
        // if (change) {
        //     console.log(change);
        // }
    }
    
    ngAfterViewInit() {
        if ($.fn.selectpicker) {
            $('select:not(.ms)').selectpicker();
        }
    }
    
    ngOnInit() {
        const dataModel = {
            dFromDate: this.yesterday,
            dToDate: this.today,
            iPartnerID: 21,
            iCurrencyID: 6
        };
        this.getGGR(dataModel);
    }
    
    ngOnChanges() {
        this.searchForm.reset({
                dFromDate: '',
                dToDate: '',
                iPartnerID: 21,
                iCurrnecyID: 6
            }
        );
    }
    
    parseUrlSegments() {
        this.urlSegments.push(this.route.snapshot.parent.url[ 0 ].path);
        this.urlSegments.push(this.route.snapshot.url[ 0 ].path);
        // segments.forEach((c, i, a) => {if (!c) {a.splice([ i ], 1);}});
    }
    
    createForm() {
        this.searchForm = this.fb.group({
            dFromDate: [ this.yesterday, Validators.compose([ Validators.required ]) ],
            dToDate: [ this.today, Validators.compose([ Validators.required ]) ],
            iPartnerID: [ 21, Validators.compose([ Validators.required ]) ],
            iCurrencyID: [ 6, Validators.compose([ Validators.required ]) ],
        });
    }
    
    onSubmit() {
        this.getGGR(this.searchForm.value);
    }
    
    getGGR(dataModel) {
        
        
        const obs$ = new Observable((observer) => {
            
            const poll$ = interval(100).subscribe(
                () => {
                    if (this.api.getSocketState() === 1) {
                        this.api.getGGR(observer, dataModel);
                        poll$.unsubscribe();
                    }
                },
                () => {
                    console.log('error');
                    poll$.unsubscribe();
                },
                () => {
                    console.log('completed');
                    poll$.unsubscribe();
                });
        });
        
        obs$.subscribe(
            {
                next: (payload: object) => {
                    this.GGR = [];
                    for (const product in payload) {
                        if (payload.hasOwnProperty(product)) {
                            if (product === 'casino') {
                                for (const dataChunk of payload[ product ]) {
                                    // console.log(dataChunk);
                                    
                                    const finalDataChunch: Array<string> = [
                                        product,
                                        dataChunk[ 'provider' ],
                                        dataChunk[ 'casinoName' ],
                                        dataChunk[ 'bet' ],
                                        dataChunk[ 'win' ],
                                        dataChunk[ 'GrossRevenue' ]
                                    ];
                                    this.GGR.push(finalDataChunch);
                                }
                            } else if (product === 'sports') {
                                for (const dataChunk of payload[ product ]) {
                                    // console.log(dataChunk);
                                    
                                    const finalDataChunch: Array<string> = [
                                        product,
                                        'BC',
                                        dataChunk[ 'casinoName' ],
                                        dataChunk[ 'bet' ],
                                        dataChunk[ 'win' ],
                                        dataChunk[ 'GrossRevenue' ]
                                    ];
                                    this.GGR.push(finalDataChunch);
                                }
                            }
                        }
                    }
                    
                    const checkIfInitialized = $('.js-exportable');
                    if (checkIfInitialized instanceof $.fn.dataTable.Api) {
                        checkIfInitialized.empty();
                    }
                    checkIfInitialized.DataTable({
                        destroy: true,
                        dom: 'Bfr<"table-responsive"t>ip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        data: this.GGR,
                        responsive: true,
                        bAutoWidth: false
                    });
                },
                error: (payload) => {
                    console.log(payload);
                }
            }
        );
    }
    
    
}
