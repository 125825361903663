import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DOCUMENT} from '@angular/common';


@Component({
    selector: 'app-search',
    templateUrl: './iframe.component.html',
    styleUrls: [ './iframe.component.scss' ]
})
export class IframeComponent implements OnInit {
    // +----------------------------------------------------+
    // | component properties                               |
    // +----------------------------------------------------+
    public token: string;
    private readonly permissions: Object;
    public iFrameUrl: SafeUrl;
    public segments: Array<string>;
    public segment1: string;
    public segment2: string;
    private readonly uri: string;
    
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    constructor(@Inject('LOCALSTORAGE') private localStorage: any,
                public route: ActivatedRoute,
                @Inject(DOCUMENT) document: any,
                private translate: TranslateService,
                public sanitizer: DomSanitizer,
                public device: DeviceDetectorService
    ) {
        translate.setDefaultLang('en');
        translate.use(translate.getBrowserLang());
        this.segments = document.location.pathname.split('/');
        this.segment1 = this.route.snapshot.parent.url[ 0 ].path;
        this.segment2 = this.route.snapshot.url[ 0 ].path;
        this.uri = this.segments[ 1 ] + '/' + this.segments[ 2 ] + '/' + this.segments[ 3 ];
        this.token = JSON.parse(this.localStorage.userData).token;
        this.permissions = JSON.parse(this.localStorage.userData).permissions;
        
        for (const key in this.permissions) {
            if (this.permissions.hasOwnProperty(key)) {
                if (key.toLocaleLowerCase() ===
                    'routing/' + this.uri) {
                    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                        this.permissions[ key ][ 'view' ][ 'desc' ] + '?token=' + this.token
                    );
                }
            }
        }
    }
    
    // +----------------------------------------------------+
    // | methods                                            |
    // +----------------------------------------------------+
    ngOnInit() {
    }
    
}
