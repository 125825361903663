// +----------------------------------------------------+
// | imports                                            |
// +----------------------------------------------------+
import {Inject, Injectable} from '@angular/core';
import {LoggerService} from './logger.service';

@Injectable()
export class DatabaseService {
    // +----------------------------------------------------+
    // | component properties                               |
    // +----------------------------------------------------+
    public db: any;
    private DB_NAME = 'admin';
    private DB_VERSION = 7;
    
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    constructor(@Inject('WINDOW') private windowRef: any,
                private log: LoggerService) {
    }
    
    // +----------------------------------------------------+
    // | general methods                                    |
    // +----------------------------------------------------+
    getObjectStore(store_name, mode) {
        const tx = this.db.transaction(store_name, mode);
        return tx.objectStore(store_name);
    }
    
    clearObjectStore(store_name) {
        const store = this.getObjectStore(store_name, 'readwrite');
        const rq = store.clear();
        rq.onsuccess = (evt) => {
            this.log.c('  Store cleared  ', 'success', 2);
        };
        rq.onerror = (evt) => {
            this.log.c(' clearObjectStore: ' + evt.target.errorCode, 'error', 8);
        };
    }
    
    checkDB() {
        if (!('indexedDB' in this.windowRef)) {
            this.log.c('  This browser doesn\'t support IndexedDB  ', 'error', 8);
            return false;
        }
        return true;
    }
    
    // +----------------------------------------------------+
    // | schemas / table definitions                        |
    // +----------------------------------------------------+
    createPayments() {
        const objectStore = this.db.createObjectStore('payments', {keyPath: 'id', autoIncrement: true});
        objectStore.createIndex('MTY_cName', 'MTY_cName', {unique: false});
        objectStore.createIndex('MTY_iID', 'MTY_iID', {unique: false});
        objectStore.createIndex('casinoID', 'casinoID', {unique: false});
        objectStore.createIndex('direction', 'direction', {unique: false});
        objectStore.createIndex('directionID', 'directionID', {unique: false});
        objectStore.createIndex('exchangeRateId', 'exchangeRateId', {unique: false});
        objectStore.createIndex('maxDate', 'maxDate', {unique: false});
        objectStore.createIndex('minDate', 'minDate', {unique: false});
        objectStore.createIndex('statusID', 'statusID', {unique: false});
        objectStore.createIndex('statusName', 'statusName', {unique: false});
        objectStore.createIndex('transactionAmountSum', 'transactionAmountSum', {unique: false});
        objectStore.createIndex('transactionCount', 'transactionCount', {unique: false});
        objectStore.createIndex('directionIDAndMTY_iID', [ 'directionID', 'MTY_iID' ], {unique: false});
        objectStore.createIndex('directionIDminDatemaxDatecasinoID', [ 'directionID', 'casinoID', 'minDate', 'maxDate' ], {unique: false});
        objectStore.createIndex('directionIDminDatemaxDatecasinoID2', [ 'directionID', 'minDate', 'maxDate', 'casinoID' ], {unique: false});
        
    }
    
    createGGR() {
        const objectStore = this.db.createObjectStore('ggr', {keyPath: 'id', autoIncrement: true});
        objectStore.createIndex('MTY_cName', 'MTY_cName', {unique: false});
        objectStore.createIndex('MTY_iID', 'MTY_iID', {unique: false});
    }
    
    createAdminLogin() {
        const objectStore = this.db.createObjectStore('adminInfo', {keyPath: 'cUserName', unique: true});
        objectStore.createIndex('cPassword', 'cPassword', {unique: false});
        objectStore.createIndex('dDateTime', 'dDateTime', {unique: false});
    }
    
    // +----------------------------------------------------+
    // | version upgrade statements                         |
    // +----------------------------------------------------+
    upgradeToVersion0(ev) {
        // console.log('upgradeToVersion0');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.deleteIndex('statusID');
        
    }
    
    upgradeToVersion1(ev) {
        // console.log('upgradeToVersion1');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.createIndex('statusID', 'statusID', {unique: false});
        
    }
    
    upgradeToVersion2(ev) {
        // console.log('upgradeToVersion2');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.deleteIndex('statusID');
        // this.createPayments();
        this.createPayments();
        this.createGGR();
    }
    
    upgradeToVersion3(ev) {
        // console.log('upgradeToVersion3');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.createIndex('statusID', 'statusID', {unique: false});
        // this.createGGR();
    }
    
    upgradeToVersion4(ev) {
        // console.log('upgradeToVersion4');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.createIndex('statusID', 'statusID', {unique: false});
        // this.createGGR();
    }
    
    upgradeToVersion5(ev) {
        // console.log('upgradeToVersion5');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.createIndex('statusID', 'statusID', {unique: false});
        // this.createGGR();
    }
    
    upgradeToVersion6(ev) {
        // console.log('upgradeToVersion5');
        // const objectStore = ev.target.transaction.objectStore('payments');
        // objectStore.createIndex('statusID', 'statusID', {unique: false});
        this.createAdminLogin();
    }
    
    upgradeToVersion7(ev) {

    }
    
    // +----------------------------------------------------+
    // | setup database / database connection               |
    // +----------------------------------------------------+
    
    setupSchema(dbversion?: number) {
        
        let rq;
        
        !!dbversion ? rq = this.windowRef.indexedDB.open(this.DB_NAME, dbversion) : rq = this.windowRef.indexedDB.open(this.DB_NAME);
        
        rq.onerror = (ev) => {
            this.log.c('  cannot connect to IndexedDB  ', 'error', 8);
            console.log(ev);
        };
        
        rq.onclose = (ev) => {
            this.log.c('  DB close event called  ', 'warning', 4);
            this.setupSchema();
        };
        
        rq.onsuccess = (ev) => {
            this.db = rq.result;
            
            this.db.onerror = (eve) => {
                this.log.c('  DB issue: ' + eve.target.errorCode, 'error', 8);
            };
            this.log.c('  DB onsuccess connection established  ', 'success', 2);
            
            if (this.DB_VERSION !== this.db.version) {
                this.log.c('  DB Version is not the same  ', 'warning', 4);
                this.db.close();
                this.setupSchema(this.DB_VERSION);
            } else {
            
            }
        };
        
        rq.onupgradeneeded = (ev) => {
            this.db = ev.target.result;
            
            this.db.onerror = (eve) => {
                this.log.c('  DB issue: ' + eve.target.errorCode, 'error', 8);
            };
            this.log.c('  DB onupgradeneeded connection established  ', 'success', 2);
            
            // console.log(this.db.version);
            // console.log(ev.oldVersion);
            
            if (ev.oldVersion === 0) {
                this.log.c('  Unupgradeneeded, DB Version is not the same  ', 'warning', 4);
                this.db.close();
                this.setupSchema(this.DB_VERSION);
                return;
            }
            
            // if (!this.db.objectStoreNames.contains('payments')) {
            
            if (ev.oldVersion < this.DB_VERSION) {
                let oldVersion = ev.oldVersion;
                while (oldVersion < this.DB_VERSION) {
                    oldVersion++;
                    const methodName = 'upgradeToVersion' + oldVersion;
                    this[ methodName ](ev);
                }
            }
            
            /*
            if (!this.db.objectStoreNames.contains('payments')) {
                // this.createPayments();
                let oldVersion = 0;
                while (oldVersion < this.DB_VERSION) {
                    oldVersion++;
                    const methodName = 'upgradeToVersion' + oldVersion;
                    this[ methodName ](ev);
                }
            } else {
                if (ev.oldVersion < this.DB_VERSION) {
                    let oldVersion = ev.oldVersion;
                    while (oldVersion < this.DB_VERSION) {
                        oldVersion++;
                        const methodName = 'upgradeToVersion' + oldVersion;
                        this[ methodName ](ev);
                    }
                }
            }
            */
        };
    }
}
