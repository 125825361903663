import {Component, OnInit} from '@angular/core';
import {LoggerService} from './_services/logger.service';
import {DatabaseService} from './_services/database.service';

@Component({
    selector: 'app-init',
    template: `
		<!-- Page Loader -->
		<div class="page-loader-wrapper" style="background-color: #474747 !important;">
			<div class="loader">
				<div class="m-t-30">
					<img class="zmdi-hc-spin" src="../assets/Icons/favicon_package/android-chrome-512x512.png" width="48" height="48" alt="sQuare">
				</div>
				<p>Just sit tight <br>and hold on for a moment ...</p>
			</div>
		</div>
		<router-outlet></router-outlet>
    `,
    styles: []
})
export class InitComponent implements OnInit {
    
    private intro = `
Hi there! Welcome to the

 _   _           _     _        _____
| | | |_ __  ___(_) __| | ___  |__  /___  _ __   ___
| | | | '_ \\/ __| |/ _\` |/ _ \\   / // _ \\| '_ \\ / _ \\
| |_| | |_) \\__ \\ | (_| |  __/_ / /| (_) | | | |  __/
 \\___/| .__/|___/_|\\__,_|\\___(_)____\\___/|_| |_|\\___|
      |_|


If you are looking for Dev Jobs write an email to info@upside.zone. We are always looking for people that know what to look for in the console.

In case you are just strolling around to check what this app is based on, keep going and don´t hesitate to contact the https://BonkersBet.com crew (whom we have developed this app for) in case you have any questions.
`;
    
    constructor(private log: LoggerService, private db: DatabaseService) {
        this.log.c(this.intro, 'intro', 16);
        // todo: Workflow for DB not working
        this.db.checkDB() ? this.db.setupSchema() : this.log.c('no indexedDB available in your terrible browser', 'error', 8);
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
    
                console.log('worjker ready');
                navigator.serviceWorker.register('/ngsw-worker.js').then(
                    (ev) => {
                        console.log('onfullfilled');
                        console.log(ev);
                    },
                    (ev) => {
                        console.log('onrejected');
                        console.log(ev);
                    });
            });
        }
    }
    
    ngOnInit() {
        console.log('something');
    

        /*
        console.log(navigator.serviceWorker);
        navigator.serviceWorker.ready.then(
            () => {
                
                console.log('worjker ready');
                if (navigator.serviceWorker.controller) {
                    
                    navigator.serviceWorker.register('/ngsw-worker.js').then(
                        (ev) => {
                            console.log('onfullfilled');
                            console.log(ev);
                        },
                        (ev) => {
                            console.log('onrejected');
                            console.log(ev);
                        });
                    
                } else {
                
                }
            },
            () => {
                console.log('worjker not ready');
            }
        );
        */
        
    }
}
