var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
// +----------------------------------------------------+
// | imports                                            |
// +----------------------------------------------------+
import { OnInit, AfterViewInit, Renderer2, OnChanges, SimpleChange, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// import {NavigationService} from './_services/navigation.service';
import { BehaviorSubject, Observable } from 'rxjs';
// import * as $ from "jquery";
import { ApiService } from './_services/api.service';
import { Connection, ConfigService } from './_services/config.service';
import { DatabaseService } from './_services/database.service';
var AppComponent = /** @class */ (function () {
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    function AppComponent(renderer, router, 
    // private nav: NavigationService,
    localStorage, api, config, db, connection, ref) {
        var _this = this;
        this.renderer = renderer;
        this.router = router;
        this.localStorage = localStorage;
        this.api = api;
        this.config = config;
        this.db = db;
        this.connection = connection;
        this.ref = ref;
        // +----------------------------------------------------+
        // | component properties                               |
        // +----------------------------------------------------+
        this.previousUrl = 'main';
        this.permissions = {};
        this.routingModel = {};
        this.connected = false;
        if (this.connection.offline) {
            var getOfflineUserData = this.db.getObjectStore('adminInfo', 'readwrite');
            var offlineUserData = getOfflineUserData.get(ConfigService.offlineUser);
            offlineUserData.onsuccess = function (offlineUserDataEvent) {
                _this.offlineUserData = offlineUserDataEvent.target.result;
                _this.adminDetails = _this.offlineUserData.miscData.admin;
                _this.adminName = _this.adminDetails['screenname'];
                _this.adminTitle = _this.adminDetails['phone'];
                _this.getRoutingModel();
            };
            offlineUserData.onerror = function (offlineUserDataEventError) {
                console.log(offlineUserDataEventError);
            };
        }
        else {
            this.adminDetails = JSON.parse(this.localStorage.userData).admin;
            this.adminName = this.adminDetails['screenname'];
            this.adminTitle = this.adminDetails['phone'];
            this.getRoutingModel();
        }
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (_this.previousUrl) {
                    // this.renderer.removeClass(document.body, this.previousUrl);
                }
                var currentUrl = event.url.split('/');
                var currentUrlSlug = currentUrl[currentUrl.length - 1];
                if (currentUrlSlug) {
                    // this.renderer.addClass(document.body, currentUrlSlug);
                }
                _this.previousUrl = currentUrlSlug;
                setTimeout(function () {
                    _this.slideToggle();
                }, 4000);
            }
        });
    }
    // +----------------------------------------------------+
    // | methods                                            |
    // +----------------------------------------------------+
    AppComponent.prototype.getPermissionModel = function () {
        var _a, _b, _c;
        var userData = this.connection.offline ? this.offlineUserData.miscData.permissions : JSON.parse(this.localStorage.userData).permissions;
        // this.permissions = userData.permissions;
        for (var element in userData) {
            if (userData.hasOwnProperty(element) && element.match(/^navigation/gmi)) {
                var navTree = element.match(/^Navigation\/(.*)/)[1].split('/');
                if (this.permissions[navTree[0]]) {
                    Object.assign(this.permissions[navTree[0]], (_a = {}, _a[navTree[1]] = userData[element], _a));
                }
                else {
                    Object.assign(this.permissions, (_b = {}, _b[navTree[0]] = (_c = {}, _c[navTree[1]] = userData[element], _c), _b));
                }
            }
        }
        // console.log(this.permissions);
    };
    AppComponent.prototype.offlineSwitchChange = function (status) {
        var _this = this;
        this.config.setOfflineModus(status);
        if (status) {
            new Observable(function (observer) {
                !!JSON.parse(_this.localStorage.getItem('userData')) ?
                    _this.api.getData(observer, JSON.parse(_this.localStorage.getItem('userData')).token) :
                    _this.api.getData(observer, null);
            }).subscribe(function (ev) {
                // console.log(ev);
            });
        }
    };
    AppComponent.prototype.getRoutingModel = function () {
        var _a, _b, _c, _d, _e, _f;
        var userData = this.connection.offline ? this.offlineUserData.miscData.permissions : JSON.parse(this.localStorage.userData).permissions;
        // this.permissions = userData.permissions;
        console.log(userData);
        for (var element in userData) {
            if (userData.hasOwnProperty(element) && element.match(/^routing/gmi)) {
                var navTree = element.match(/^Routing\/(.*)/)[1].split('/');
                if (this.routingModel[navTree[0]]) {
                    if (this.routingModel[navTree[0]][navTree[1]]) {
                        Object.assign(this.routingModel[navTree[0]][navTree[1]], (_a = {}, _a[navTree[2]] = userData[element], _a));
                    }
                    else {
                        Object.assign(this.routingModel[navTree[0]], (_b = {}, _b[navTree[1]] = (_c = {}, _c[navTree[2]] = userData[element], _c), _b));
                    }
                }
                else {
                    Object.assign(this.routingModel, (_d = {},
                        _d[navTree[0]] = (_e = {}, _e[navTree[1]] = (_f = {}, _f[navTree[2]] = userData[element], _f), _e),
                        _d));
                }
            }
        }
        // console.log('routing object');
        this.routingModel = __assign({}, this.routingModel);
        // this.slideToggle();
        // $.AdminsQuare.navbar.activate();
    };
    AppComponent.prototype.logoutUser = function () {
        var _this = this;
        var sub = new BehaviorSubject('');
        sub.subscribe({
            next: function (payload) {
                if (payload) {
                    console.log(payload);
                    _this.localStorage.removeItem('userData');
                    _this.router.navigate(['authentication/sign-in']);
                    sub.complete();
                }
            },
            error: function (payload) {
                console.log(payload);
                sub.complete();
            }
        });
        this.api.logoutUser(sub);
    };
    AppComponent.prototype.ngOnChanges = function (changes) {
        var previousUrl = changes.previousUrl;
        // console.log('prev value: ', previousUrl.previousValue);
        // console.log('got name: ', previousUrl.currentValue);
    };
    AppComponent.prototype.slideToggle = function () {
        // Collapse or Expand Menu
        $('.menu-toggle').off('click');
        $('.menu-toggle').on('click', function (e) {
            // console.log('# Here at Collapse or Expand');
            var $this = $(this);
            var $content = $this.next();
            if ($($this.parents('ul')[0]).hasClass('list')) {
                var $not = $(e.target).hasClass('menu-toggle') ? e.target : $(e.target).parents('.menu-toggle');
                $.each($('.menu-toggle.toggled').not($not).next(), function (i, val) {
                    if ($(val).is(':visible')) {
                        $(val).prev().toggleClass('toggled');
                        $(val).slideUp();
                    }
                });
            }
            $this.toggleClass('toggled');
            $content.slideToggle(320);
        });
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.slideToggle();
        $.AdminsQuare.browser.activate();
        $.AdminsQuare.leftSideBar.activate();
        $.AdminsQuare.rightSideBar.activate();
        $.AdminsQuare.rightchat.activate();
        $.AdminsQuare.navbar.activate();
        $.AdminsQuare.select.activate();
        $('.page-loader-wrapper').fadeOut();
    };
    AppComponent.prototype.ngOnInit = function () {
        $(function () {
            'use strict';
            skinChanger();
            CustomScrollbar();
            initSparkline();
            initCounters();
            CustomPageJS();
        });
        // Sparkline
        function initSparkline() {
            $('.sparkline').each(function () {
                var $this = $(this);
                $this.sparkline('html', $this.data());
            });
        }
        // Counters JS
        function initCounters() {
            $('.count-to').countTo();
        }
        // Skin changer
        function skinChanger() {
            $('.right-sidebar .choose-skin li').on('click', function () {
                var $body = $('body');
                var $this = $(this);
                var existTheme = $('.right-sidebar .choose-skin li.active').data('theme');
                $('.right-sidebar .choose-skin li').removeClass('active');
                $body.removeClass('theme-' + existTheme);
                $this.addClass('active');
                $body.addClass('theme-' + $this.data('theme'));
            });
        }
        // All Custom Scrollbar JS
        function CustomScrollbar() {
            $('.sidebar .menu .list').slimscroll({
                height: 'calc(100vh - 65px)',
                color: 'rgba(0,0,0,0.2)',
                position: 'left',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            $('.navbar-left .dropdown-menu .body .menu').slimscroll({
                height: '300px',
                color: 'rgba(0,0,0,0.2)',
                size: '3px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            $('.right_chat .chat_body .chat-widget').slimscroll({
                height: 'calc(100vh - 145px)',
                color: 'rgba(0,0,0,0.1)',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '2px',
                position: 'left',
            });
            $('.right-sidebar .slim_scroll').slimscroll({
                height: 'calc(100vh - 60px)',
                color: 'rgba(0,0,0,0.4)',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
        }
        function CustomPageJS() {
            $('.boxs-close').on('click', function () {
                var element = $(this);
                var cards = element.parents('.card');
                cards.addClass('closed').fadeOut();
            });
            // Theme Light and Dark  ============
            $('.theme-light-dark .t-light').on('click', function () {
                $('body').removeClass('menu_dark');
            });
            $('.theme-light-dark .t-dark').on('click', function () {
                $('body').addClass('menu_dark');
            });
            $('.menu-sm').on('click', function () {
                $('body').toggleClass('menu_sm');
            });
            // Chat widget js ====
            $(document).ready(function () {
                $('.btn_overlay').on('click', function () {
                    $('.overlay_menu').fadeToggle(200);
                    $(this).toggleClass('btn-open').toggleClass('btn-close');
                });
            });
            $('.overlay_menu').on('click', function () {
                $('.overlay_menu').fadeToggle(200);
                $('.overlay_menu button.btn').toggleClass('btn-open').toggleClass('btn-close');
            });
            // =========
            $('.form-control').on('focus', function () {
                $(this).parent('.input-group').addClass('input-group-focus');
            }).on('blur', function () {
                $(this).parent('.input-group').removeClass('input-group-focus');
            });
        }
        // Fullscreen
        $(function () {
            'use strict';
            $('#supported').text('Supported/allowed: ' + !!screenfull.enabled);
            if (!screenfull.enabled) {
                return false;
            }
            $('#request').on('click', function () {
                screenfull.request($('#container')[0]);
                // Does not require jQuery. Can be used like this too:
                // screenfull.request(document.getElementById('container'));
            });
            $('#exit').on('click', function () {
                screenfull.exit();
            });
            $('[data-provide~="boxfull"]').on('click', function () {
                screenfull.toggle($('.box')[0]);
            });
            $('[data-provide~="fullscreen"]').on('click', function () {
                screenfull.toggle($('#container')[0]);
            });
            // var selector = '[data-provide~="boxfull"]';
            var selector = '[data-provide~="fullscreen"]';
            $(selector).each(function () {
                $(this).data('fullscreen-default-html', $(this).html());
            });
            document.addEventListener(screenfull.raw.fullscreenchange, function () {
                if (screenfull.isFullscreen) {
                    $(selector).each(function () {
                        $(this).addClass('is-fullscreen');
                    });
                }
                else {
                    $(selector).each(function () {
                        $(this).removeClass('is-fullscreen');
                    });
                }
            });
            function fullscreenchange() {
                var elem = screenfull.element;
                $('#status').text('Is fullscreen: ' + screenfull.isFullscreen);
                if (elem) {
                    $('#element').text('Element: ' + elem.localName + (elem.id ? '#' + elem.id : ''));
                }
                if (!screenfull.isFullscreen) {
                    $('#external-iframe').remove();
                    document.body.style.overflow = 'auto';
                }
            }
            screenfull.on('change', fullscreenchange);
            // Set the initial values
            fullscreenchange();
        }); // End of use strict
    };
    return AppComponent;
}());
export { AppComponent };
