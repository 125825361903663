import {Inject, Injectable} from '@angular/core';
import {Connection, ConfigService} from './config.service';
import {DatabaseService} from './database.service';
import {Observable} from 'rxjs';

@Injectable()
export class CheckWidgetPermissionsService {
    
    constructor(@Inject('LOCALSTORAGE') private localstorage: any, private db: DatabaseService, private connection: Connection
    ) {
    }
    
    key(widgetName) {
        const permission = 'Widgets/' + widgetName;
        return new Observable((observer) => {
            if (this.connection.offline) {
                const getOfflinePermissionData = this.db.getObjectStore('adminInfo', 'readwrite');
                const offlinePermissionData = getOfflinePermissionData.get(ConfigService.offlineUser);
                offlinePermissionData.onsuccess = (offlinePermissionDataEvent) => {
                    const result = offlinePermissionDataEvent.target.result;
                    observer.next(!!result.miscData.permissions[ permission ]);
                };
                offlinePermissionData.onerror = (offlinePermissionDataEventError) => {
                    console.log(offlinePermissionDataEventError);
                };
            } else {
                observer.next(!!JSON.parse(this.localstorage.userData).permissions[ permission ]);
            }
        });
        
    }
}
