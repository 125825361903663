import { ModuleWithProviders } from '@angular/core';
import { ConfigService, UniqueIDService, UniqueStringService, Connection } from '../_services/config.service';
import { ApiService } from '../_services/api.service';
import { WebsocketService } from '../_services/websocket.service';
import { AuthGuard } from '../_services/auth.guard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CheckWidgetPermissionsService } from '../_services/check-widget-permissions.service';
import { DatabaseService } from '../_services/database.service';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [
                WebsocketService,
                ApiService,
                ConfigService,
                Connection,
                UniqueIDService,
                UniqueStringService,
                AuthGuard,
                CheckWidgetPermissionsService,
                DatabaseService
            ]
        };
    };
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
