import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OperationsRoutingModule} from './operations-routing.module';
import {TicketsComponent} from './tickets/tickets.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        OperationsRoutingModule
    ],
    declarations: [ TicketsComponent ]
})
export class OperationsModule {
}
