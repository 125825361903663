import {Injectable} from '@angular/core';
import {Connection, ConfigService} from './config.service';
import {Observable, BehaviorSubject} from 'rxjs';
import {LoggerService} from './logger.service';

@Injectable()
export class WebsocketService {
    binaryType: string;
    private rand: number;
    public reconnect = false;
    public instance: WebSocket;
    public checkSocketState: any;
    public opened = new BehaviorSubject<any>('');
    public message = new BehaviorSubject<any>('');
    public subscribe: any;
    public callbacks: Object = {};
    private openLog = `
+--------------------------------------+
|          socket ONOPEN fired         |
+--------------------------------------+
`;
    private messageLog = `
+--------------------------------------+
|        socket ONMESSAGE fired        |
+--------------------------------------+
`;
    private closeLog = `
+--------------------------------------+
|          socket ONCLOSE fired        |
+--------------------------------------+
`;
    
    constructor(public log: LoggerService, public config: Connection) {
        this.message.subscribe({
            next: event => {
                if (this.callbacks[ event.params ]) {
                    this.callbacks[ event.params ](event);
                }
            }
        });
        this.instance = this.createInstance();
        this.addSocketHandlers();
    }
    
    public createInstance() {
        return new WebSocket(ConfigService.webSocketUrl);
    }
    
    public addSocketHandlers() {
        this.instance.onopen = () => {
            this.config.setConnectionStatus(true);
            this.log.c(this.openLog, 'socketOpen', 2);
            
            // console.log(event);
            // dispatch application wide synthetic onOpen event / client notification
            // growl.notification('Connected', 'info', 'WebSocket Connection established!', 3000);
            /* var openingEvent = new CustomEvent( 'WebSocketOpen' );
                   window.dispatchEvent( openingEvent );*/
            
            this.opened.next(this.getSocketState());
        };
        
        this.instance.onmessage = event => {
            this.log.c(this.messageLog, 'socketMessage', 2);
            const data = JSON.parse(event.data);
            this.log.c(data, '', 2);
            this.message.next(data);
        };
        
        this.instance.onerror = event => {
            // not doing anything here right now because socket triggers onerror AND onclose anyway.
        };
        this.instance.onclose = event => {
            this.config.setConnectionStatus(false);
            this.log.c(this.closeLog, 'socketClose', 8);
            this.log.c(event, '', 8);
            this.reconnect = true;
            clearInterval(this.checkSocketState);
            // growl.notification('Disconnected', 'danger', 'Unfortunately either the <b>internet connection went
            // missing</b> or the server disconnected!<br> We are now trying every couple of seconds to get the
            // connection back. <br>If this issue persists, call for help! ', 7000);
            setTimeout(() => {
                this.instance = this.createInstance();
                this.addSocketHandlers();
            }, 6000);
        };
    }
    
    public getSocketState() {
        if (!this.instance) {
            this.instance = this.createInstance();
            this.addSocketHandlers();
        }
        return this.instance.readyState;
    }
}
