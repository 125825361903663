import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var PlayerModule = /** @class */ (function () {
    function PlayerModule() {
    }
    return PlayerModule;
}());
export { PlayerModule };
export { ɵ0 };
