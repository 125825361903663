import {Directive, ElementRef, Renderer2, Input, OnInit, OnDestroy} from '@angular/core';

declare var $: any;

@Directive({
    selector: '[appDatetimepicker]'
})
export class DatetimepickerDirective implements OnInit, OnDestroy {
    
    private datepickerOptions: any = {
        format: 'YYYY-MM-DD HH:mm:ss',
        clearButton: true,
        // nowButton: true,
        shortTime: false,
        weekStart: 1,
        time: true
    };
    private $el: any;
    
    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.$el = $(el.nativeElement);
    }
    
    ngOnInit() {
        // Initialize the date picker
        this.$el.bootstrapMaterialDatePicker(this.datepickerOptions)
        // Angular is watching for the "input" event which is not fired when choosing
        // a date within the datepicker, so watch for the "changeDate" event and manually
        // fire the "input" event so that Angular picks up the change
            .on('change', (event, date) => {
                const inputEvent = new Event('input', {bubbles: true});
                this.el.nativeElement.dispatchEvent(inputEvent);
            });
    };
    
    ngOnDestroy() {
        this.$el.bootstrapMaterialDatePicker('destroy');
    };
}
