import {Injectable, Inject} from '@angular/core';
import {WebsocketService} from './websocket.service';
import {ConfigService, UniqueIDService, Connection} from './config.service';
import {Router} from '@angular/router';
import {Md5} from 'ts-md5';
import {LoggerService} from './logger.service';

import {Observable} from '../../../node_modules/rxjs/Observable';
import {DatabaseService} from './database.service';

@Injectable()
export class ApiService extends WebsocketService {
    constructor(
        @Inject('LOCALSTORAGE') private localstorage: any,
        private logger: LoggerService,
        private router: Router,
        private db: DatabaseService,
        log: LoggerService,
        conf: Connection
    ) {
        super(log, conf);
        
        this.opened.subscribe({
            next: event => {
                if (event) {
                    // Get the heartbeat going every 30 seconds / interval is cleared onClose
                    this.checkSocketState = setInterval(() => {
                        this.ping();
                    }, 30000);
                }
                
                if (event && this.reconnect) {
                    new Observable((observer) => {
                        !!JSON.parse(this.localstorage.getItem('userData')) ?
                            this.getData(observer, JSON.parse(this.localstorage.getItem('userData')).token) :
                            this.getData(observer, null);
                        
                    }).subscribe((ev) => {
                        this.log.c(ev, '', 2);
                    });
                }
            }
        });
    }
    
    ping(): void {
        const cbID: string = UniqueIDService.uID();
        this.callbacks [ cbID ] = (payload) => {
            // this.logger.plain(payload);
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/XXXX/coninfo',
            'payload': [],
            'params': cbID
        }));
    }
    
    getRegistrations(obs$, startDate, endDate) {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                obs$.next(event.payload);
            } else if (event.status != 200) {
                obs$.error(event.payload);
            }
            delete this.callbacks[ cbID ];
        };
        this.instance.send(JSON.stringify(
            {
                'event': '/ce/statistic/registration',
                'payload': {
                    'casinoId': null,
                    'dateFrom': startDate,
                    'dateTo': endDate
                },
                'params': cbID
            }
        ));
    }
    
    getGGR(obs$, dataModel) {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                obs$.next(event.payload);
            } else if (event.status != 200) {
                obs$.error(true);
            }
            obs$.complete();
            delete this.callbacks[ cbID ];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/statistic/ggr',
            'payload': {
                'casinoId': dataModel.iPartnerID === 'null' ? null : dataModel.iPartnerID,
                'dateFrom': dataModel.dFromDate,
                'dateTo': dataModel.dToDate,
                'currencyId': dataModel.iCurrencyID
            },
            'params': cbID
        }));
    }
    
    getPayments(obs$, dataModel) {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                obs$.next(event.payload);
            } else if (event.status != 200) {
                obs$.error(true);
            }
            obs$.complete();
            delete this.callbacks[ cbID ];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/statistic/payment',
            'payload': {
                'casinoId': dataModel.iPartnerID === 'null' ? null : dataModel.iPartnerID,
                'dateFrom': dataModel.dFromDate,
                'dateTo': dataModel.dToDate
            },
            'params': cbID
        }));
    }
    
    addUser(userModel, sub): void {
        // console.log(userModel);
        // console.log(userModel.controls['cAddress'].value);
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                sub.next(event.payload);
            } else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            
            delete this.callbacks[ cbID ];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/player/add',
            'payload': {
                'cUserName': userModel.controls[ 'cUserName' ].value,
                'cPassword': userModel.controls[ 'cPassword' ].value
            },
            'params': cbID
        }));
    }
    
    getData(obs$, token) {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                obs$.next(true);
                obs$.complete();
            } else if (event.status != 200) {
                this.tokenLogin(obs$, token);
            }
            delete this.callbacks[ cbID ];
        };
        
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/get',
            'payload': [],
            'params': cbID
        }));
    }
    
    tokenLogin(obs$, token): void {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                obs$.next(true);
            } else if (event.status != 200) {
                this.localstorage.removeItem('userData');
                this.router.navigate([ 'authentication/sign-in' ]);
                obs$.next(false);
            }
            obs$.complete();
            delete this.callbacks[ cbID ];
        };
        
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/loginByToken',
            'payload': {
                'token': token
            },
            'params': cbID
        }));
    }
    
    loginUser(userModel, sub): void {
        // console.log(userModel);
        // console.log(userModel.controls['cAddress'].value);
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                
                const validateAdminStore = this.db.getObjectStore('adminInfo', 'readwrite');
                const updateAdmin = validateAdminStore.get(event.payload.admin.username);
                updateAdmin.onsuccess = (updateAdminEvent) => {
                    const record = updateAdminEvent.target.result;
                    record.bValidated = true;
                    record.token = event.payload.token;
                    record.miscData = event.payload;
                    validateAdminStore.put(record);
                };
                updateAdmin.onerror = (updateAdminEventError) => {
                    console.log(updateAdminEventError);
                };
                
                sub.next(event.payload);
            } else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            delete this.callbacks[ cbID ];
        };
        
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/login',
            'payload': {
                'username': userModel.controls[ 'cUserName' ].value,
                'password': Md5.hashStr(userModel.controls[ 'cPassword' ].value)
            },
            'params': cbID
        }));
    }
    
    logoutUser(sub): void {
        const cbID: string = UniqueIDService.uID();
        this.callbacks[ cbID ] = (event) => {
            if (event.status == 200) {
                sub.next(event.payload);
            } else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            delete this.callbacks[ cbID ];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/logout',
            'payload': {},
            'params': cbID
        }));
    }
    
    
}
