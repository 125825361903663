import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
var ɵ0 = { preload: false }, ɵ1 = { preload: false }, ɵ2 = { preload: false }, ɵ3 = { preload: false };
var routes = [
    {
        path: '',
        component: AppComponent,
        children: [
            {
                path: '',
                loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
                data: ɵ0
            },
            {
                path: 'dashboard',
                loadChildren: 'app/dashboard/dashboard.module#DashboardModule',
                data: ɵ1
            },
            {
                path: 'app',
                loadChildren: 'app/app/app.module#AppModule'
            },
            {
                path: 'reporting',
                loadChildren: 'app/reporting/reporting.module#ReportingModule'
            },
            {
                path: 'gratuity',
                loadChildren: 'app/gratuity/gratuity.module#GratuityModule'
            },
            {
                path: 'operations',
                loadChildren: 'app/operations/operations.module#OperationsModule'
            },
            {
                path: 'user-interface',
                loadChildren: 'app/user-interface/user-interface.module#UserInterfaceModule'
            },
            {
                path: 'widgets',
                loadChildren: 'app/widgets/widgets.module#WidgetsModule'
            },
            {
                path: 'forms',
                loadChildren: 'app/forms/forms.module#FormsModule'
            },
            {
                path: 'tables',
                loadChildren: 'app/tables/tables.module#TablesModule'
            },
            {
                path: 'charts',
                loadChildren: 'app/charts/charts.module#ChartsModule'
            },
            {
                path: 'maps',
                loadChildren: 'app/maps/maps.module#MapsModule'
            },
            {
                path: 'sample-pages',
                loadChildren: 'app/sample-pages/sample-pages.module#SamplePagesModule'
            },
            {
                path: 'overview',
                loadChildren: 'app/overview/overview.module#OverviewModule'
            },
            {
                path: 'financial',
                loadChildren: 'app/reporting/reporting.module#ReportingModule'
            },
            {
                path: 'reconciliation',
                loadChildren: 'app/reporting/reporting.module#ReportingModule'
            },
            {
                path: 'sportsbook',
                loadChildren: 'app/operations/operations.module#OperationsModule'
            },
            {
                path: 'player',
                loadChildren: 'app/player/player.module#PlayerModule'
            },
            {
                path: 'admin',
                loadChildren: 'app/admin/admin.module#AdminModule',
                data: ɵ2
            },
            {
                path: 'transactions',
                loadChildren: 'app/transactions/transactions.module#TransactionsModule',
                data: ɵ3
            }
        ]
    }
];
var NavigationRoutingModule = /** @class */ (function () {
    function NavigationRoutingModule() {
    }
    return NavigationRoutingModule;
}());
export { NavigationRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
