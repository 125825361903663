import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigService, UniqueIDService, UniqueStringService, Connection} from '../_services/config.service';
import {ApiService} from '../_services/api.service';
import {WebsocketService} from '../_services/websocket.service';
import {FlatObjectPipe} from '../_pipes/flat-object.pipe';
import {AuthGuard} from '../_services/auth.guard';
import {DatetimepickerDirective} from '../_directives/datetimepicker.directive';
import {IframeComponent} from './iframe/iframe.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {RegistrationsLastXxxDaysComponent} from './registrations-last-xxx-days/registrations-last-xxx-days.component';
import {BetsLastXxxDaysComponent} from './bets-last-xxx-days/bets-last-xxx-days.component';
import {PlayerActivityLastXxxDaysComponent} from './player-activity-last-xxx-days/player-activity-last-xxx-days.component';
import {GgrLastXxxDaysComponent} from './ggr-last-xxx-days/ggr-last-xxx-days.component';
import {CheckWidgetPermissionsService} from '../_services/check-widget-permissions.service';
import {DatabaseService} from '../_services/database.service';
import {UiSwitchModule} from 'ngx-ui-switch';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        UiSwitchModule,
        TranslateModule.forChild({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    deps: [ HttpClient ]
                }
            }
        ) ],
    declarations: [
        FlatObjectPipe,
        DatetimepickerDirective,
        IframeComponent,
        RegistrationsLastXxxDaysComponent,
        BetsLastXxxDaysComponent,
        PlayerActivityLastXxxDaysComponent,
        GgrLastXxxDaysComponent
    ],
    exports: [
        CommonModule,
        FlatObjectPipe,
        DatetimepickerDirective,
        IframeComponent,
        RegistrationsLastXxxDaysComponent,
        BetsLastXxxDaysComponent,
        PlayerActivityLastXxxDaysComponent,
        GgrLastXxxDaysComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                WebsocketService,
                ApiService,
                ConfigService,
                Connection,
                UniqueIDService,
                UniqueStringService,
                AuthGuard,
                CheckWidgetPermissionsService,
                DatabaseService
            ]
        };
    }
}
