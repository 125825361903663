import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IframeComponent} from '../shared/iframe/iframe.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'user/setup',
        pathMatch: 'full'
    },
    {
        path: 'user',
        children: [
            {
                path: 'setup',
                component: IframeComponent
            },
            {
                path: 'divisions',
                component: IframeComponent
            },
            {
                path: 'overview',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'casino',
        children: [
            {
                path: 'permissions',
                component: IframeComponent
            },
            {
                path: 'groups',
                component: IframeComponent
            },
            {
                path: 'setup',
                component: IframeComponent
            },
            {
                path: 'config',
                component: IframeComponent
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class AdminRoutingModule {
}
