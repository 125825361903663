import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IframeComponent} from '../shared/iframe/iframe.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'account/search',
        pathMatch: 'full'
    },
    {
        path: 'account',
        children: [
            {
                path: 'search',
                component: IframeComponent
            },
            {
                path: 'block',
                component: IframeComponent
            },            {
                path: 'paymentblock',
                component: IframeComponent
            },
            {
                path: 'close',
                component: IframeComponent
            },
            {
                path: 'bulktrack',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'communication',
        children: [
            {
                path: 'tickets',
                component: IframeComponent
            },
            {
                path: 'editor',
                component: IframeComponent
            },            {
                path: 'templates',
                component: IframeComponent
            },
            {
                path: 'history',
                component: IframeComponent
            },            {
                path: 'send',
                component: IframeComponent
            }
        ]
    },
    {
        path: 'affiliate',
        children: [
            {
                path: 'accounts',
                component: IframeComponent
            },
            {
                path: 'tracking',
                component: IframeComponent
            }
        ]
    },
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class PlayerRoutingModule {
}
