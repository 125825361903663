// +----------------------------------------------------+
// | imports                                            |
// +----------------------------------------------------+
import {
    Component,
    OnInit,
    AfterViewInit,
    Renderer2,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    Inject, ChangeDetectorRef
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
// import {NavigationService} from './_services/navigation.service';
import {BehaviorSubject, Observable} from 'rxjs';


// import * as $ from "jquery";
import {ApiService} from './_services/api.service';
import {Connection, ConfigService} from './_services/config.service';
import {DatabaseService} from './_services/database.service';

declare const $: any;
declare const jquery: any;
declare const screenfull: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
    // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnChanges, OnInit, AfterViewInit {
    // +----------------------------------------------------+
    // | component properties                               |
    // +----------------------------------------------------+
    previousUrl = 'main';
    permissions: object = {};
    routingModel: object = {};
    private adminDetails: Object;
    public adminName: string;
    public adminTitle: string;
    public connected: boolean;
    private offlineUserData: any;
    
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    constructor(
        private renderer: Renderer2,
        private router: Router,
        // private nav: NavigationService,
        @Inject('LOCALSTORAGE') private localStorage: any,
        private api: ApiService,
        public config: Connection,
        private db: DatabaseService,
        private connection: Connection,
        private ref: ChangeDetectorRef
    ) {
        this.connected = false;
        
        if (this.connection.offline) {
            const getOfflineUserData = this.db.getObjectStore('adminInfo', 'readwrite');
            const offlineUserData = getOfflineUserData.get(ConfigService.offlineUser);
            offlineUserData.onsuccess = (offlineUserDataEvent) => {
                this.offlineUserData = offlineUserDataEvent.target.result;
                this.adminDetails = this.offlineUserData.miscData.admin;
                this.adminName = this.adminDetails[ 'screenname' ];
                this.adminTitle = this.adminDetails[ 'phone' ];
                this.getRoutingModel();
                
            };
            offlineUserData.onerror = (offlineUserDataEventError) => {
                console.log(offlineUserDataEventError);
            };
        } else {
            this.adminDetails = JSON.parse(this.localStorage.userData).admin;
            this.adminName = this.adminDetails[ 'screenname' ];
            this.adminTitle = this.adminDetails[ 'phone' ];
            this.getRoutingModel();
            
        }
        
        this.router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    if (this.previousUrl) {
                        // this.renderer.removeClass(document.body, this.previousUrl);
                    }
                    const currentUrl = event.url.split('/');
                    const currentUrlSlug = currentUrl[ currentUrl.length - 1 ];
                    if (currentUrlSlug) {
                        // this.renderer.addClass(document.body, currentUrlSlug);
                    }
                    this.previousUrl = currentUrlSlug;
                    setTimeout(
                        () => {
                            this.slideToggle();
                        }, 4000
                    );
                }
            }
        );
    }
    
    // +----------------------------------------------------+
    // | methods                                            |
    // +----------------------------------------------------+
    getPermissionModel() {
        const userData =
            this.connection.offline ? this.offlineUserData.miscData.permissions : JSON.parse(this.localStorage.userData).permissions;
        // this.permissions = userData.permissions;
        
        for (const element in userData) {
            if (userData.hasOwnProperty(element) && element.match(/^navigation/gmi)) {
                const navTree = element.match(/^Navigation\/(.*)/)[ 1 ].split('/');
                if (this.permissions[ navTree[ 0 ] ]) {
                    Object.assign(this.permissions[ navTree[ 0 ] ], {[ navTree[ 1 ] ]: userData[ element ]});
                    
                } else {
                    Object.assign(this.permissions, {[ navTree[ 0 ] ]: {[ navTree[ 1 ] ]: userData[ element ]}});
                }
            }
        }
        // console.log(this.permissions);
    }
    
    offlineSwitchChange(status) {
        this.config.setOfflineModus(status);
        if (status) {
            new Observable((observer) => {
                !!JSON.parse(this.localStorage.getItem('userData')) ?
                    this.api.getData(observer, JSON.parse(this.localStorage.getItem('userData')).token) :
                    this.api.getData(observer, null);
            }).subscribe((ev) => {
                // console.log(ev);
            });
        }
    }
    
    getRoutingModel() {
        const userData =
            this.connection.offline ? this.offlineUserData.miscData.permissions : JSON.parse(this.localStorage.userData).permissions;
        // this.permissions = userData.permissions;
        
        console.log(userData);
        for (const element in userData) {
            if (userData.hasOwnProperty(element) && element.match(/^routing/gmi)) {
                const navTree = element.match(/^Routing\/(.*)/)[ 1 ].split('/');
                if (this.routingModel[ navTree[ 0 ] ]) {
                    
                    if (this.routingModel[ navTree[ 0 ] ][ navTree[ 1 ] ]) {
                        Object.assign(this.routingModel[ navTree[ 0 ] ][ navTree[ 1 ] ], {[ navTree[ 2 ] ]: userData[ element ]});
                    } else {
                        Object.assign(this.routingModel[ navTree[ 0 ] ], {[ navTree[ 1 ] ]: {[ navTree[ 2 ] ]: userData[ element ]}});
                    }
                    
                } else {
                    Object.assign(this.routingModel, {
                        [ navTree[ 0 ] ]: {[ navTree[ 1 ] ]: {[ navTree[ 2 ] ]: userData[ element ]}}
                    });
                }
            }
        }
        
        // console.log('routing object');
        this.routingModel = {...this.routingModel};
        // this.slideToggle();
        // $.AdminsQuare.navbar.activate();
        
    }
    
    logoutUser() {
        const sub = new BehaviorSubject<any>('');
        sub.subscribe({
            next: (payload) => {
                if (payload) {
                    console.log(payload);
                    this.localStorage.removeItem('userData');
                    this.router.navigate([ 'authentication/sign-in' ]);
                    sub.complete();
                }
            },
            error: (payload) => {
                console.log(payload);
                sub.complete();
            }
        });
        this.api.logoutUser(sub);
    }
    
    ngOnChanges(changes: SimpleChanges) {
        const previousUrl: SimpleChange = changes.previousUrl;
        // console.log('prev value: ', previousUrl.previousValue);
        // console.log('got name: ', previousUrl.currentValue);
    }
    
    
    slideToggle() {
        // Collapse or Expand Menu
        $('.menu-toggle').off('click');
        $('.menu-toggle').on('click', function (e) {
            // console.log('# Here at Collapse or Expand');
            const $this = $(this);
            const $content = $this.next();
            
            if ($($this.parents('ul')[ 0 ]).hasClass('list')) {
                const $not = $(e.target).hasClass('menu-toggle') ? e.target : $(e.target).parents('.menu-toggle');
                
                $.each($('.menu-toggle.toggled').not($not).next(), function (i, val) {
                    if ($(val).is(':visible')) {
                        $(val).prev().toggleClass('toggled');
                        $(val).slideUp();
                    }
                });
            }
            
            $this.toggleClass('toggled');
            $content.slideToggle(320);
        });
    }
    
    ngAfterViewInit() {
        this.slideToggle();
        $.AdminsQuare.browser.activate();
        $.AdminsQuare.leftSideBar.activate();
        $.AdminsQuare.rightSideBar.activate();
        $.AdminsQuare.rightchat.activate();
        $.AdminsQuare.navbar.activate();
        $.AdminsQuare.select.activate();
        $('.page-loader-wrapper').fadeOut();
    }
    
    ngOnInit() {
        $(function () {
            'use strict';
            skinChanger();
            CustomScrollbar();
            initSparkline();
            initCounters();
            CustomPageJS();
        });
        
        // Sparkline
        function initSparkline() {
            $('.sparkline').each(function () {
                const $this = $(this);
                $this.sparkline('html', $this.data());
            });
        }
        
        // Counters JS
        function initCounters() {
            $('.count-to').countTo();
        }
        
        // Skin changer
        function skinChanger() {
            $('.right-sidebar .choose-skin li').on('click', function () {
                const $body = $('body');
                const $this = $(this);
                
                const existTheme = $('.right-sidebar .choose-skin li.active').data('theme');
                $('.right-sidebar .choose-skin li').removeClass('active');
                $body.removeClass('theme-' + existTheme);
                $this.addClass('active');
                $body.addClass('theme-' + $this.data('theme'));
            });
        }
        
        // All Custom Scrollbar JS
        function CustomScrollbar() {
            $('.sidebar .menu .list').slimscroll({
                height: 'calc(100vh - 65px)',
                color: 'rgba(0,0,0,0.2)',
                position: 'left',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            
            $('.navbar-left .dropdown-menu .body .menu').slimscroll({
                height: '300px',
                color: 'rgba(0,0,0,0.2)',
                size: '3px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            
            $('.right_chat .chat_body .chat-widget').slimscroll({
                height: 'calc(100vh - 145px)',
                color: 'rgba(0,0,0,0.1)',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '2px',
                position: 'left',
            });
            
            $('.right-sidebar .slim_scroll').slimscroll({
                height: 'calc(100vh - 60px)',
                color: 'rgba(0,0,0,0.4)',
                size: '2px',
                alwaysVisible: false,
                borderRadius: '3px',
                railBorderRadius: '0'
            });
            
        }
        
        function CustomPageJS() {
            $('.boxs-close').on('click', function () {
                const element = $(this);
                const cards = element.parents('.card');
                cards.addClass('closed').fadeOut();
            });
            
            // Theme Light and Dark  ============
            $('.theme-light-dark .t-light').on('click', function () {
                $('body').removeClass('menu_dark');
            });
            
            $('.theme-light-dark .t-dark').on('click', function () {
                $('body').addClass('menu_dark');
            });
            
            $('.menu-sm').on('click', function () {
                $('body').toggleClass('menu_sm');
            });
            // Chat widget js ====
            $(document).ready(function () {
                $('.btn_overlay').on('click', function () {
                    $('.overlay_menu').fadeToggle(200);
                    $(this).toggleClass('btn-open').toggleClass('btn-close');
                });
            });
            $('.overlay_menu').on('click', function () {
                $('.overlay_menu').fadeToggle(200);
                $('.overlay_menu button.btn').toggleClass('btn-open').toggleClass('btn-close');
            });
            // =========
            $('.form-control').on('focus', function () {
                $(this).parent('.input-group').addClass('input-group-focus');
            }).on('blur', function () {
                $(this).parent('.input-group').removeClass('input-group-focus');
            });
        }
        
        // Fullscreen
        $(function () {
            'use strict';
            $('#supported').text('Supported/allowed: ' + !!screenfull.enabled);
            
            if (!screenfull.enabled) {
                return false;
            }
            
            $('#request').on('click', function () {
                screenfull.request($('#container')[ 0 ]);
                // Does not require jQuery. Can be used like this too:
                // screenfull.request(document.getElementById('container'));
            });
            
            $('#exit').on('click', function () {
                screenfull.exit();
            });
            
            $('[data-provide~="boxfull"]').on('click', function () {
                screenfull.toggle($('.box')[ 0 ]);
            });
            
            $('[data-provide~="fullscreen"]').on('click', function () {
                screenfull.toggle($('#container')[ 0 ]);
            });
            
            // var selector = '[data-provide~="boxfull"]';
            const selector = '[data-provide~="fullscreen"]';
            
            $(selector).each(function () {
                $(this).data('fullscreen-default-html', $(this).html());
            });
            
            document.addEventListener(screenfull.raw.fullscreenchange, function () {
                if (screenfull.isFullscreen) {
                    $(selector).each(function () {
                        $(this).addClass('is-fullscreen');
                    });
                } else {
                    $(selector).each(function () {
                        $(this).removeClass('is-fullscreen');
                    });
                }
            });
            
            
            function fullscreenchange() {
                const elem = screenfull.element;
                
                $('#status').text('Is fullscreen: ' + screenfull.isFullscreen);
                
                if (elem) {
                    $('#element').text('Element: ' + elem.localName + (elem.id ? '#' + elem.id : ''));
                }
                
                if (!screenfull.isFullscreen) {
                    $('#external-iframe').remove();
                    document.body.style.overflow = 'auto';
                }
            }
            
            screenfull.on('change', fullscreenchange);
            
            // Set the initial values
            fullscreenchange();
        }); // End of use strict
    }
}
