import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
var ɵ0 = (createTranslateLoader);
var ReportingModule = /** @class */ (function () {
    function ReportingModule() {
    }
    return ReportingModule;
}());
export { ReportingModule };
export { ɵ0 };
