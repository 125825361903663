import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GratuityRoutingModule } from './gratuity-routing.module';
import { BonusComponent } from './bonus/bonus.component';

@NgModule({
  imports: [
    CommonModule,
    GratuityRoutingModule
  ],
  declarations: [BonusComponent, ]
})
export class GratuityModule { }
