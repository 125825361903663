import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './app-routing.module';
import {InitComponent} from './init.component';
import {NavigationModule} from './navigation.module';
import {ReportingModule} from './reporting/reporting.module';
import {OperationsModule} from './operations/operations.module';
import {GratuityModule} from './gratuity/gratuity.module';
import {PlayerModule} from './player/player.module';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {AdminModule} from './admin/admin.module';
import {TransactionsModule} from './transactions/transactions.module';
import {LoggerService} from './_services/logger.service';
import {UiSwitchModule} from 'ngx-ui-switch';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function getLocalStorage() {
    return (typeof window !== 'undefined') ? window.localStorage : null;
}

export function getWindow() {
    return (typeof window !== 'undefined') ? window : null;
}

@NgModule({
    declarations: [
        InitComponent
    ],
    imports: [
        BrowserModule,
        
        UiSwitchModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [ HttpClient ]
            }
        }),
        NavigationModule,
        ReportingModule,
        OperationsModule,
        GratuityModule,
        SharedModule.forRoot(),
        PlayerModule,
        DeviceDetectorModule.forRoot(),
        AdminModule,
        TransactionsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        {provide: 'LOCALSTORAGE', useFactory: getLocalStorage},
        {provide: 'WINDOW', useFactory: getWindow},
        LoggerService
    ],
    bootstrap: [ InitComponent ]
})
export class AppModule {
}

