import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppComponent} from './app.component';
import {NavigationRoutingModule} from './navigation-routing.module';
import {SharedModule} from './shared/shared.module';
import {UiSwitchModule} from 'ngx-ui-switch';

@NgModule({
    imports: [
        CommonModule,
        NavigationRoutingModule,
        SharedModule,
        UiSwitchModule
    ],
    declarations: [ AppComponent ]
})
export class NavigationModule {
}
