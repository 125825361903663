
		<!-- Page Loader -->
		<div class="page-loader-wrapper" style="background-color: #474747 !important;">
			<div class="loader">
				<div class="m-t-30">
					<img class="zmdi-hc-spin" src="../assets/Icons/favicon_package/android-chrome-512x512.png" width="48" height="48" alt="sQuare">
				</div>
				<p>Just sit tight <br>and hold on for a moment ...</p>
			</div>
		</div>
		<router-outlet></router-outlet>
    