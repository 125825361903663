import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ConfigService {
    // this guy is being set by main.ts dynamically based on the values set in the environment object.
    // public static connectionStatus = false;
    
    public static offlineUser: string;
    
    public static webSocketUrl;
    
    public static counter = 0;
    
    public static countries: Array<string> = [
        'Afghanistan',
        'Albania',
        'Algeria',
        'American Samoa',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'British Virgin Islands',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Cayman Islands',
        'Central African Republic',
        'Chad',
        'Chile',
        'Christmas Island',
        'Cocos(Keeling) Islands',
        'Colombia',
        'Comoros',
        'Congo',
        'Cook Islands',
        'Costa Rica',
        'Cote dIvoire',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'Gabon',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea - Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macau',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Martinique',
        'Mauritania',
        'Mauritius',
        'Mayotte',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Myanmar',
        'Nagorno - Karabakh',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Niue',
        'Norfolk Island',
        'North Korea',
        'Northern Mariana',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peoples Republic of China',
        'Peru',
        'Philippines',
        'Pitcairn Islands',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Republic of China',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Barthelemy',
        'Saint Helena',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Martin',
        'Saint Pierre and Miquelon',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'Somaliland',
        'South Africa',
        'South Korea',
        'South Ossetia',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Svalbard',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'The Bahamas',
        'The Gambia',
        'Timor - Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Transnistria Pridnestrovie',
        'Trinidad and Tobago',
        'Tristan da Cunha',
        'Tunisia',
        'Turkey',
        'Turkish Republic of Northern Cyprus',
        'Turkmenistan',
        'Turks and Caicos Islands',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'US Virgin Islands',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Wallis and Futuna',
        'Western Sahara',
        'Yemen',
        'Zambia',
        'Zimbabwe',
    ];
    
    public static genders: Array<string> = [ 'Male', 'Female' ];
    
    public static languages: Array<string> = [ 'en', 'de', 'it', 'fr' ];
    
    public static social: Array<string> = [ 'Facebook', 'Twitter', 'Skype', 'Telegram' ];
    
    public static wallets: Array<string> = [ 'ETH', 'BTC', 'WIRE', 'CASH' ];
    
    constructor() {
    
    }
}


@Injectable()
export class Connection {
    
    public connectionStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public offlineModeUnavailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public offlineModeDisabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
    
    public offline = true;
    
    
    constructor() {
        
        // this.connectionStatus.subscribe({
        //     next: (status) => {
        //         console.log('connectionStatus: ' + status);
        //     }
        // });
        // this.offlineModeUnavailable.subscribe({
        //     next: (status) => {
        //         console.log('offlineModeUnavailable: ' + status);
        //     }
        // });
        // this.offlineModeDisabled.subscribe({
        //     next: (status) => {
        //         console.log('offlineModeDisabled: ' + status);
        //         console.log('offline: ' + this.offline);
        //     }
        // });
    }
    
    public setConnectionStatus(status: boolean): void {
        // The not so obvious but important question here is why the hack I need THREE subscriptions to handle connections states. So the
        // deal is this:
        // 1. connectionStatus --> is needed for the Connected Badge and the Wifi symbol in the header to show where the connection is at
        // 2. offlineModeUnavailable --> this is used for the on / off switch to ensure that users cannot just go and enable online mode
        // when the app is actually disconnected, i.e. connectionStatus = false
        // 3. offlineMode --> this is needed for situations where
        // Offline Mode is enforced by users or connection state so the application knows where to actually grab the data from [Server ||
        // IndexedDB]
        
        // if connection is open, enable the offlineMode, otherwise disable the switch so users can´t choose Online.
        this.offlineModeUnavailable.next(!status);
        
        // if true, connected true, offline false | else connected false, offline true
        this.connectionStatus.next(status);
        this.offlineModeDisabled.next(status);
        this.offline = !status;
    }
    
    public setOfflineModus(status: boolean): void {
        // this is used to actually set the value of the switch / checkbox as otherwise the switch, when pressing the button appears to
        // have changed but the state of the app remains ONLINE mode
        this.offline = !status;
        this.offlineModeDisabled.next(status);
    }
}

@Injectable()
export class UniqueIDService {
    
    constructor() {
    }
    
    public static uID(): string {
        return Math.random().toString(16);
    }
}


@Injectable()
export class UniqueStringService {
    
    constructor() {
    }
    
    public static uString(length: number, chars: string): string {
        let mask = '';
        if (chars.indexOf('a') > -1) {
            mask += 'abcdefghijklmnopqrstuvwxyz';
        }
        if (chars.indexOf('A') > -1) {
            mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        }
        if (chars.indexOf('#') > -1) {
            mask += '0123456789';
        }
        if (chars.indexOf('!') > -1) {
            mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        }
        let result = '';
        for (let i = length; i > 0; --i) {
            result += mask[ Math.floor(Math.random() * mask.length) ];
        }
        return result;
    }
}

