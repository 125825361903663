import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IframeComponent} from '../shared/iframe/iframe.component';
import {GgrComponent} from '../reporting/ggr/ggr.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'sportsbook',
        pathMatch: 'full'
    },
    {
        path: 'sportsbook',
        children: [
            {
                path: 'betslips',
                component: IframeComponent
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class OperationsRoutingModule {
}
