<!-- Main Content -->
<section class="content">
	<div class="block-header">
		<div class="row">
			<div class="col-lg-7 col-md-6 col-sm-12">
				<h2>{{route.snapshot.url[0].path | uppercase}}
					<small>{{'Reporting-GGR_tagline' | translate}}</small>
				</h2>
			</div>
			<div class="col-lg-5 col-md-6 col-sm-12">
				<button class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10" type="button">
					<i class="zmdi zmdi-plus"></i>
				</button>
				<ul class="breadcrumb float-md-right">
					<li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> home</a></li>
					<li *ngFor="let segment of urlSegments" class="breadcrumb-item">{{segment}}</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="row clearfix">
			<div class="col-lg-12">
				<div class="card">
					<div class="header">
						<h2><strong>Filter</strong> Options</h2>
						<ul class="header-dropdown">
							<li class="dropdown">
								<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
									<i class="zmdi zmdi-more"></i> </a>
								<ul class="dropdown-menu dropdown-menu-right">
									<li><a href="javascript:void(0);">Action</a></li>
									<li><a href="javascript:void(0);">Another action</a></li>
									<li><a href="javascript:void(0);">Something else</a></li>
								</ul>
							</li>
							<li class="remove">
								<a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
							</li>
						</ul>
					</div>
					<div class="body">


						<form [formGroup]="searchForm" (ngSubmit)="onSubmit()" novalidate class="form">
							<div class="row">

								<!--From Date-->
								<!--##########################################-->
								<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
									<div class="input-group">
	                                    <span class="input-group-addon">
	                                        <i class="zmdi zmdi-calendar"></i>
	                                    </span>
										<input
											appDatetimepicker
											type="text"
											class="form-control datetimepicker"
											placeholder="Please choose date & time..."
											formControlName="dFromDate"
										>
									</div>
									<div class="text-center alert-danger">
							            <span
								            *ngIf="!searchForm.get('dFromDate').pristine && searchForm.get('dFromDate').touched && searchForm.get('dFromDate').invalid"
								            class=""> From Date Missing
					                    </span>
									</div>
								</div>

								<!--To Date-->
								<!--##########################################-->
								<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
									<div class="input-group">
	                                    <span class="input-group-addon">
	                                        <i class="zmdi zmdi-calendar"></i>
	                                    </span>
										<input
											appDatetimepicker
											type="text"
											class="form-control datetimepicker"
											placeholder="Please choose date & time..."
											formControlName="dToDate"
										>
									</div>
									<div class="text-center alert-danger">
							            <span
								            *ngIf="!searchForm.get('dToDate').pristine && searchForm.get('dToDate').touched && searchForm.get('dToDate').invalid"
								            class=""> To Date Missing
					                    </span>
									</div>
								</div>

								<!--Partner ID-->
								<!--##########################################-->
								<div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
									<div class="input-group">
										<select
											class="form-control show-tick"
											formControlName="iPartnerID">
											<option *ngFor="let partner of partnerIDs" [ngValue]="partner.id">
												{{ partner.name }}
											</option>
										</select>

									</div>
								</div>

								<!--Submit Button-->
								<!--##########################################-->
								<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 text-center">
									<div>
										<button
											class="btn btn-sm btn-primary"
											[disabled]="searchForm.pristine || searchForm.invalid"
										>Send
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid">
		<!-- Exportable Table -->
		<div class="row clearfix">
			<div class="col-lg-12">
				<div class="card">
					<div class="header">
						<h2><strong>{{'financialPayments_Header_1'|translate}}</strong>
							{{'financialPayments_Header_2'|translate}} </h2>
						<ul class="header-dropdown">
							<li class="dropdown">
								<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
									<i class="zmdi zmdi-more"></i> </a>
								<ul class="dropdown-menu dropdown-menu-right slideUp float-right">
									<li><a href="javascript:void(0);">Coming Soon</a></li>
								</ul>
							</li>
							<li class="remove">
								<a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
							</li>
						</ul>
					</div>
					<div class="body">

						<table id="deposits" class="table table-bordered table-striped table-hover dataTable js-exportable">
							<thead>
							<tr>
								<th>key</th>
								<th>MinDate</th>
								<th>MaxDate</th>
								<th>Moneytype</th>
								<th>Partner</th>
								<th>Currency</th>
								<th>Status</th>
								<th>Count</th>
								<th>Sum</th>
							</tr>
							</thead>
							<tfoot>
							<tr>
								<th>key</th>
								<th>MinDate</th>
								<th>MaxDate</th>
								<th>Moneytype</th>
								<th>Partner</th>
								<th>Currency</th>
								<th>Status</th>
								<th>Count</th>
								<th>Sum</th>
							</tr>
							</tfoot>
						</table>


					</div>

					<div class="body">

						<table id="cashouts" class="table table-bordered table-striped table-hover dataTable js-exportable">
							<thead>
							<tr>
								<th>key</th>
								<th>MinDate</th>
								<th>MaxDate</th>
								<th>Moneytype</th>
								<th>Partner</th>
								<th>Currency</th>
								<th>Status</th>
								<th>Count</th>
								<th>Sum</th>
							</tr>
							</thead>
							<tfoot>
							<tr>
								<th>key</th>
								<th>MinDate</th>
								<th>MaxDate</th>
								<th>Moneytype</th>
								<th>Partner</th>
								<th>Currency</th>
								<th>Status</th>
								<th>Count</th>
								<th>Sum</th>
							</tr>
							</tfoot>
						</table>


					</div>

				</div>
			</div>
		</div>
		<!-- #END# Exportable Table -->
	</div>


</section>