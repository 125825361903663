<!-- Main Content -->
<section class="content">
	<div class="block-header">
		<div class="row">
			<div class="col-lg-7 col-md-6 col-sm-12">
				<h2>{{segment1 | uppercase}} {{segment2 | uppercase}}
					<small>{{'iFramePage_TagLine'| translate}}</small>
				</h2>
			</div>
			<div class="col-lg-5 col-md-6 col-sm-12">
				<ul class="breadcrumb float-md-right">
					<li class="breadcrumb-item"><a routerLink="/dashboard"><i class="zmdi zmdi-home"></i> home</a></li>
					<li class="breadcrumb-item">{{segment1}}</li>
					<li class="breadcrumb-item active">{{segment2}}</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="row clearfix">
			<div class="col-lg-12">
				<div class="card">
					<div class="header">
						<h2><strong>{{'iFramePage_Host'|translate}}</strong> {{'iFramePage_Tab'|translate}}</h2>
						<ul class="header-dropdown">
							<li class="dropdown">
								<a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
									<i class="zmdi zmdi-more"></i> </a>
								<ul class="dropdown-menu dropdown-menu-right">
									<li><a href="javascript:void(0);">Action</a></li>
									<li><a href="javascript:void(0);">Another action</a></li>
									<li><a href="javascript:void(0);">Something else</a></li>
								</ul>
							</li>
							<li class="remove">
								<a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
							</li>
						</ul>
					</div>
					<div class="body text-center">

						<iframe frameborder="0" class="genericsFrame" *ngIf="device.device !== 'iphone'" [src]='iFrameUrl'></iframe>

						<div *ngIf="device.device === 'iphone'">
							<p class="text-justify">{{'iFramePage_iOS_explain'|translate}}</p>
							<a [href]="iFrameUrl" target="_blank">
								<button class="btn btn-info btn-lg">{{'iFramePage_ButtonText' | translate}}</button>
							</a>
						</div>


					</div>
				</div>
			</div>
		</div>
	</div>
</section>