import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BonusComponent} from './bonus/bonus.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'bonus',
        pathMatch: 'full'
    },
    {
        path: 'bonus',
        component: BonusComponent
    },
    {
        path: 'combinations',
        component: BonusComponent
    },
    {
        path: 'gogw',
        component: BonusComponent
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class GratuityRoutingModule {
}
