import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {Observable, Subscription, interval} from 'rxjs';
import {tokenReference} from '@angular/compiler';
import {ApiService} from './api.service';
import {LoggerService} from './logger.service';
import {DatabaseService} from './database.service';
import {Connection, ConfigService} from './config.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    
    constructor(
        private router: Router,
        @Inject('LOCALSTORAGE') private localstorage: any,
        @Inject(ApiService) private api: ApiService,
        private log: LoggerService,
        private db: DatabaseService,
        private connection: Connection
    ) {
        // private token: string = JSON.parse(this.localstorage.getItem('userData').token || '');
    }
    
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        this.log.c('  AuthGuard  #canActivate called  ', 'component', 4);
        
        if (this.connection.offline && ConfigService.offlineUser) {
            return true;
        }
        
        if (!!JSON.parse(this.localstorage.getItem('userData'))) {
            return new Observable((observer) => {
                
                
                const poll$ = interval(100).subscribe(
                    () => {
                        if (this.api.getSocketState() === 1) {
                            this.api.getData(observer, JSON.parse(this.localstorage.getItem('userData')).token);
                            poll$.unsubscribe();
                        }
                    },
                    () => {
                        console.log('error');
                    },
                    () => {
                        console.log('completed');
                    });
            });
        } else {
            this.router.navigate([ 'authentication/sign-in' ]);
            return false;
        }
    }
    
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        this.log.c('  AuthGuard  #canActivateChild called  ', 'component', 2);
        
        if (this.connection.offline && ConfigService.offlineUser) {
            return true;
        }
        
        if (!!JSON.parse(this.localstorage.getItem('userData'))) {
            return new Observable((observer) => {
                const poll$ = interval(100).subscribe(
                    () => {
                        if (this.api.getSocketState() === 1) {
                            this.api.getData(observer, JSON.parse(this.localstorage.getItem('userData')).token);
                            poll$.unsubscribe();
                        }
                    },
                    () => {
                        console.log('error');
                    },
                    () => {
                        console.log('completed');
                    });
            });
        } else {
            this.router.navigate([ 'authentication/sign-in' ]);
            return false;
        }
    }
}
