// +----------------------------------------------------+
// | imports                                            |
// +----------------------------------------------------+
import {Component, DoCheck, OnInit, ViewEncapsulation, IterableDiffers, OnChanges, Inject, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../_services/api.service';
import {Observable, Subscription, interval} from 'rxjs';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../_services/logger.service';
import {DatabaseService} from '../../_services/database.service';
import {Connection} from '../../_services/config.service';

declare const $: any;

export class Partner {
    public name?: string;
    public id?: number;
}

export class Currency {
    public name?: string;
    public id?: number;
}

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: [ './payments.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class PaymentsComponent implements OnInit, DoCheck, OnChanges, AfterViewInit {
    // +----------------------------------------------------+
    // | component properties                               |
    // +----------------------------------------------------+
    urlSegments: Array<string> = [];
    readyState = 0;
    ids = [];
    minDates = [];
    maxDates = [];
    searchForm: FormGroup;
    formerrors: string;
    Deposits: Array<Array<string>> = [];
    Cashouts: Array<Array<string>> = [];
    differ: any;
    
    private DB_STORE_NAME = 'payments';
    
    public currencies: Array<Currency> = [
        {name: 'Euro', id: 6},
        {name: 'US Dollar', id: 1}
    ];
    public partnerIDs: Array<Partner> = [];
    private today: string = moment()
        .subtract(1, 'days')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format('YYYY-MM-DD HH:mm:ss');
    private yesterday: string = moment()
        .subtract(90, 'days')
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD HH:mm:ss');
    
    // +----------------------------------------------------+
    // | constructor                                        |
    // +----------------------------------------------------+
    constructor(private api: ApiService,
                private differs: IterableDiffers,
                private fb: FormBuilder,
                @Inject('LOCALSTORAGE') private localStorage: any,
                public route: ActivatedRoute,
                private translate: TranslateService,
                private log: LoggerService,
                private db: DatabaseService,
                private connection: Connection) {
        
        // Language realted stuff to be set for each component
        translate.setDefaultLang('en');
        translate.use(translate.getBrowserLang());
        
        // Used to set the breadcrumbs
        this.parseUrlSegments();
        
        // todo: See if this thing is really needed in the future
        this.differ = differs.find([]).create(null);
        
        this.createForm();
        
        // todo: Move this thing to indexedDB asap!!!
        const casinos = JSON.parse(this.localStorage.userData).casinos;
        for (const key in casinos) {
            if (casinos.hasOwnProperty(key)) {
                let partnerUnit;
                if (casinos[ key ] === 'Default') {
                    partnerUnit = {name: casinos[ key ], id: 'null'};
                } else {
                    partnerUnit = {name: casinos[ key ], id: Number(key)};
                }
                this.partnerIDs.push(partnerUnit);
            }
        }
    }
    
    // +----------------------------------------------------+
    // | methods                                            |
    // +----------------------------------------------------+
    ngDoCheck() {
        // todo: this refers to the same as the "differ" prop up in the property declarations. This works but it might not be needed.
        // const change = this.differ.diff(this.GGR);
        // if (change) {
        //     console.log(change);
        // }
    }
    
    ngAfterViewInit() {
        if ($.fn.selectpicker) {
            $('select:not(.ms)').selectpicker();
        }
    }
    
    ngOnInit() {
        const dataModel = {
            dFromDate: this.yesterday,
            dToDate: this.today,
            iPartnerID: 21
        };
        
        this.getPayments(dataModel);
        
        // this.queryDeposits(dataModel);
        // this.queryCashouts();
        // this.queryCashouts();
        // this.getPayments(dataModel);
    }
    
    ngOnChanges() {
        this.searchForm.reset({
                dFromDate: '',
                dToDate: '',
                iPartnerID: 21
            }
        );
    }
    
    parseUrlSegments() {
        this.urlSegments.push(this.route.snapshot.parent.url[ 0 ].path);
        this.urlSegments.push(this.route.snapshot.url[ 0 ].path);
    }
    
    createForm() {
        this.searchForm = this.fb.group({
            dFromDate: [ this.yesterday, Validators.compose([ Validators.required ]) ],
            dToDate: [ this.today, Validators.compose([ Validators.required ]) ],
            iPartnerID: [ 21, Validators.compose([ Validators.required ]) ]
        });
    }
    
    onSubmit() {
        
        this.getPayments(this.searchForm.value);
        
        
        // this.getPayments(this.searchForm.value);
        
        
        // this.query5();
        // this.query1();
        // this.query2();
        // this.query3(this.searchForm.value);
        // this.query4(this.searchForm.value);
        
        // this.queryDeposits(this.searchForm.value);
    }
    
    insertPayments(payload) {
        this.db.clearObjectStore(this.DB_STORE_NAME);
        const store = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite');
        
        for (const data in payload[ 'payments' ]) {
            if (payload[ 'payments' ].hasOwnProperty(data)) {
                const insert = store.add(payload[ 'payments' ][ data ]);
                
                
                insert.onsuccess = (event) => {
                    this.log.g(' Insert Transaction Group  ', 'information', 2);
                    this.log.c('  Inserted successfully ', '', 2);
                    this.log.c(event.target, '', 2);
                    this.log.gE();
                };
                
                insert.onerror = (event) => {
                    this.log.c('  Issue inserting Data ', 'error', 8);
                    this.log.c(event.target, '', 8);
                };
            }
        }
    }
    
    /**
     * @whatItDoes Querying deposits and cashouts .
     *
     * @dataModel:
     * This is the form data supplied from within the search
     *
     * @direction:
     * 1 = Deposits and 2 = Cashouts
     *
     * @viewName
     * the HTML div the table will be rendered in
     */
    queryPayments(dataModel, direction, viewName) {
        let readyState = 0;
        // console.log(dataModel);
        direction === 1 ? this.Deposits = [] : this.Cashouts = [];
        
        let tmpResults = [];
        const ids = [];
        const minDates = [];
        const maxDates = [];
        const model = dataModel;
        model.iPartnerID = model.iPartnerID.toString();
        model.iPartnerIDLow = model.iPartnerID;
        model.iPartnerIDHigh = model.iPartnerID;
        if (model.iPartnerID === 'null') {
            model.iPartnerIDLow = '0';
            model.iPartnerIDHigh = '512';
        } else {
            model.iPartnerIDLow = model.iPartnerID;
            model.iPartnerIDHigh = model.iPartnerID;
        }
        
        const poll$ = interval(100).subscribe(
            () => {
                if (readyState === 4) {
                    tmpResults = tmpResults.filter((el1) => {
                        return ids.some((el2) => {
                            return el1[ 0 ] === el2[ 0 ];
                        });
                    });
                    
                    tmpResults = tmpResults.filter((el1) => {
                        return minDates.some((el2) => {
                            return el1[ 0 ] === el2[ 0 ];
                        });
                    });
                    
                    tmpResults = tmpResults.filter((el1) => {
                        return maxDates.some((el2) => {
                            return el1[ 0 ] === el2[ 0 ];
                        });
                    });
                    
                    // console.log(tmpResults);
                    direction === 1 ? this.Deposits = tmpResults : this.Cashouts = tmpResults;
                    
                    const checkIfInitialized = $('#' + viewName);
                    
                    if (checkIfInitialized instanceof $.fn.dataTable.Api) {
                        checkIfInitialized.empty();
                    }
                    checkIfInitialized.DataTable({
                        destroy: true,
                        dom: 'Bfr<"table-responsive"t>ip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ],
                        data: direction === 1 ? this.Deposits : this.Cashouts,
                        responsive: true,
                        bAutoWidth: false
                    });
                    
                    this.log.gE();
                    poll$.unsubscribe();
                }
            },
            () => {
                console.log('error');
                poll$.unsubscribe();
            },
            () => {
                console.log('completed');
                poll$.unsubscribe();
            });
        
        this.log.g(`
.   SELECT * FROM tbl_payments WHERE          .
        directionID = id                      .
        AND casinoID = id                     .
        AND minDate BETWEEN date AND date     .
        AND maxDate BETWEEN data AND date     .
`, 'information', 2);
        // +---------------------------------------------------------+
        // | SELECT * FROM tbl_payments WHERE directionID = id ....  |
        // +---------------------------------------------------------+
        this.log.c('queryWhereDirectionEquals', '', 2);
        const queryWhereDirectionEquals = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('directionID')
            .openCursor(IDBKeyRange.only(direction.toString()))
            .onsuccess = (directionIDResultEvent) => {
            const directionIDCursor = directionIDResultEvent.target.result;
            if (directionIDCursor) {
                tmpResults.push([
                    directionIDCursor.primaryKey,
                    directionIDCursor.value[ 'minDate' ],
                    directionIDCursor.value[ 'maxDate' ],
                    directionIDCursor.value[ 'MTY_cName' ].toLowerCase(),
                    directionIDCursor.value[ 'casinoName' ].toLowerCase(),
                    directionIDCursor.value[ 'sourceCurrency' ],
                    directionIDCursor.value[ 'statusName' ],
                    directionIDCursor.value[ 'transactionCount' ],
                    Math.round(directionIDCursor.value[ 'transactionAmountSum' ])
                ]);
                directionIDCursor.continue();
            } else {
                this.log.c('  Direction ID Query finished ', 'success', 2);
                readyState++;
                // console.log(tmpResults);
            }
        };
        
        // +----------------------------------------------------+
        // | AND casinoID = id .....                            |
        // +----------------------------------------------------+
        this.log.c('andCasinoIDEquals', '', 2);
        const andCasinoIDEquals = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('casinoID')
            .openCursor(
                IDBKeyRange.bound(
                    model.iPartnerIDLow,
                    model.iPartnerIDHigh,
                    false,
                    false
                )
            )
            .onsuccess = (casinoIDResultEvent) => {
            const casinoIDCursor = casinoIDResultEvent.target.result;
            if (casinoIDCursor) {
                ids.push([ casinoIDCursor.primaryKey ]);
                casinoIDCursor.continue();
            } else {
                this.log.c('  Casino ID Query finished ', 'success', 2);
                readyState++;
            }
        };
        
        // +----------------------------------------------------+
        // | AND minDate <= dateTo .....                        |
        // +----------------------------------------------------+
        this.log.c('andMindDateLowerThan', '', 2);
        const andMindDateLowerThan = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('minDate')
            .openCursor(IDBKeyRange.upperBound(model.dToDate))
            .onsuccess = (minDateResultEvent) => {
            const minDateCursor = minDateResultEvent.target.result;
            
            if (minDateCursor) {
                minDates.push([ minDateCursor.primaryKey ]);
                minDateCursor.continue();
            } else {
                this.log.c('  Min Date Query finished ', 'success', 2);
                readyState++;
            }
        };
        
        // +----------------------------------------------------+
        // | AND maxDate >= dateFrom .....                      |
        // +----------------------------------------------------+
        this.log.c('andMaxDateBiggerThan', '', 2);
        const andMaxDateBiggerThan = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('maxDate')
            .openCursor(IDBKeyRange.lowerBound(model.dFromDate))
            .onsuccess = (maxDateResultEvent) => {
            const maxDateCursor = maxDateResultEvent.target.result;
            if (maxDateCursor) {
                maxDates.push([ maxDateCursor.primaryKey ]);
                maxDateCursor.continue();
            } else {
                this.log.c('  Max Date Query finished ', 'success', 2);
                readyState++;
            }
        };
    }
    
    getMaxDate() {
        const query = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('minDate')
            .openCursor(null, 'prev');
        
        query.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
                // console.log('Name: ' + cursor.key);
                // console.log(cursor.value);
                
            } else {
                this.log.c('  Query finished ', 'success', 2);
            }
        };
        
        query.onerror = (event) => {
            this.log.c('Issue executing query ', 'error', 8);
        };
    }
    
    getMinDate() {
        const query = this.db.getObjectStore(this.DB_STORE_NAME, 'readwrite')
            .index('minDate')
            .openCursor(null);
        
        query.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
                // console.log('Name: ' + cursor.key);
                // console.log(cursor.value);
                
                // !!cursor.key ? console.log(moment(cursor.key, 'YYYY-MM-DD HH:mm:ss')) : console.log('nothing');
                
            } else {
                this.log.c('  Query finished ', 'success', 2);
            }
        };
        
        query.onerror = (event) => {
            this.log.c('Issue executing query ', 'error', 8);
        };
    }
    
    getPayments(dataModel) {
        if (this.connection.offline) {
            this.queryPayments(dataModel, 1, 'deposits');
            this.queryPayments(dataModel, 2, 'cashouts');
        } else {
            const obs$ = new Observable((observer) => {
                const poll$ = interval(100).subscribe(
                    () => {
                        if (this.api.getSocketState() === 1) {
                            this.api.getPayments(observer, dataModel);
                            poll$.unsubscribe();
                        }
                    },
                    () => {
                        console.log('error');
                        poll$.unsubscribe();
                    },
                    () => {
                        // console.log('completed');
                        poll$.unsubscribe();
                    });
            });
            
            obs$.subscribe(
                {
                    next: (payload: object) => {
                        this.insertPayments(payload);
                        this.queryPayments(dataModel, 1, 'deposits');
                        this.queryPayments(dataModel, 2, 'cashouts');
                    },
                    error: (payload) => {
                        console.log(payload);
                    }
                }
            );
        }
    }
}
