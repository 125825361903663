/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./init.component";
import * as i3 from "./_services/logger.service";
import * as i4 from "./_services/database.service";
var styles_InitComponent = [];
var RenderType_InitComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InitComponent, data: {} });
export { RenderType_InitComponent as RenderType_InitComponent };
export function View_InitComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "page-loader-wrapper"], ["style", "background-color: #474747 !important;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "m-t-30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "sQuare"], ["class", "zmdi-hc-spin"], ["height", "48"], ["src", "../assets/Icons/favicon_package/android-chrome-512x512.png"], ["width", "48"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Just sit tight "])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["and hold on for a moment ..."])), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 9, 0); }, null); }
export function View_InitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-init", [], null, null, null, View_InitComponent_0, RenderType_InitComponent)), i0.ɵdid(1, 114688, null, 0, i2.InitComponent, [i3.LoggerService, i4.DatabaseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InitComponentNgFactory = i0.ɵccf("app-init", i2.InitComponent, View_InitComponent_Host_0, {}, {}, []);
export { InitComponentNgFactory as InitComponentNgFactory };
