var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { WebsocketService } from './websocket.service';
import { UniqueIDService, Connection } from './config.service';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';
import { LoggerService } from './logger.service';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { DatabaseService } from './database.service';
var ApiService = /** @class */ (function (_super) {
    __extends(ApiService, _super);
    function ApiService(localstorage, logger, router, db, log, conf) {
        var _this = _super.call(this, log, conf) || this;
        _this.localstorage = localstorage;
        _this.logger = logger;
        _this.router = router;
        _this.db = db;
        _this.opened.subscribe({
            next: function (event) {
                if (event) {
                    // Get the heartbeat going every 30 seconds / interval is cleared onClose
                    _this.checkSocketState = setInterval(function () {
                        _this.ping();
                    }, 30000);
                }
                if (event && _this.reconnect) {
                    new Observable(function (observer) {
                        !!JSON.parse(_this.localstorage.getItem('userData')) ?
                            _this.getData(observer, JSON.parse(_this.localstorage.getItem('userData')).token) :
                            _this.getData(observer, null);
                    }).subscribe(function (ev) {
                        _this.log.c(ev, '', 2);
                    });
                }
            }
        });
        return _this;
    }
    ApiService.prototype.ping = function () {
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (payload) {
            // this.logger.plain(payload);
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/XXXX/coninfo',
            'payload': [],
            'params': cbID
        }));
    };
    ApiService.prototype.getRegistrations = function (obs$, startDate, endDate) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                obs$.next(event.payload);
            }
            else if (event.status != 200) {
                obs$.error(event.payload);
            }
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/statistic/registration',
            'payload': {
                'casinoId': null,
                'dateFrom': startDate,
                'dateTo': endDate
            },
            'params': cbID
        }));
    };
    ApiService.prototype.getGGR = function (obs$, dataModel) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                obs$.next(event.payload);
            }
            else if (event.status != 200) {
                obs$.error(true);
            }
            obs$.complete();
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/statistic/ggr',
            'payload': {
                'casinoId': dataModel.iPartnerID === 'null' ? null : dataModel.iPartnerID,
                'dateFrom': dataModel.dFromDate,
                'dateTo': dataModel.dToDate,
                'currencyId': dataModel.iCurrencyID
            },
            'params': cbID
        }));
    };
    ApiService.prototype.getPayments = function (obs$, dataModel) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                obs$.next(event.payload);
            }
            else if (event.status != 200) {
                obs$.error(true);
            }
            obs$.complete();
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/statistic/payment',
            'payload': {
                'casinoId': dataModel.iPartnerID === 'null' ? null : dataModel.iPartnerID,
                'dateFrom': dataModel.dFromDate,
                'dateTo': dataModel.dToDate
            },
            'params': cbID
        }));
    };
    ApiService.prototype.addUser = function (userModel, sub) {
        var _this = this;
        // console.log(userModel);
        // console.log(userModel.controls['cAddress'].value);
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                sub.next(event.payload);
            }
            else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/player/add',
            'payload': {
                'cUserName': userModel.controls['cUserName'].value,
                'cPassword': userModel.controls['cPassword'].value
            },
            'params': cbID
        }));
    };
    ApiService.prototype.getData = function (obs$, token) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                obs$.next(true);
                obs$.complete();
            }
            else if (event.status != 200) {
                _this.tokenLogin(obs$, token);
            }
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/get',
            'payload': [],
            'params': cbID
        }));
    };
    ApiService.prototype.tokenLogin = function (obs$, token) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                obs$.next(true);
            }
            else if (event.status != 200) {
                _this.localstorage.removeItem('userData');
                _this.router.navigate(['authentication/sign-in']);
                obs$.next(false);
            }
            obs$.complete();
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/loginByToken',
            'payload': {
                'token': token
            },
            'params': cbID
        }));
    };
    ApiService.prototype.loginUser = function (userModel, sub) {
        var _this = this;
        // console.log(userModel);
        // console.log(userModel.controls['cAddress'].value);
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                var validateAdminStore_1 = _this.db.getObjectStore('adminInfo', 'readwrite');
                var updateAdmin = validateAdminStore_1.get(event.payload.admin.username);
                updateAdmin.onsuccess = function (updateAdminEvent) {
                    var record = updateAdminEvent.target.result;
                    record.bValidated = true;
                    record.token = event.payload.token;
                    record.miscData = event.payload;
                    validateAdminStore_1.put(record);
                };
                updateAdmin.onerror = function (updateAdminEventError) {
                    console.log(updateAdminEventError);
                };
                sub.next(event.payload);
            }
            else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/login',
            'payload': {
                'username': userModel.controls['cUserName'].value,
                'password': Md5.hashStr(userModel.controls['cPassword'].value)
            },
            'params': cbID
        }));
    };
    ApiService.prototype.logoutUser = function (sub) {
        var _this = this;
        var cbID = UniqueIDService.uID();
        this.callbacks[cbID] = function (event) {
            if (event.status == 200) {
                sub.next(event.payload);
            }
            else if (event.status != 200) {
                sub.error(event.payload.message);
            }
            delete _this.callbacks[cbID];
        };
        this.instance.send(JSON.stringify({
            'event': '/ce/admin/logout',
            'payload': {},
            'params': cbID
        }));
    };
    return ApiService;
}(WebsocketService));
export { ApiService };
